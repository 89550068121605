// import React from 'react';
// import { Modal, Button } from 'react-bootstrap';

// const DeleteDepartmentModal = ({ show, onHide, department, onDelete }) => {
//     return (
//         <Modal show={show} onHide={onHide} centered>
//             <Modal.Header closeButton>
//                 <Modal.Title>Delete Department</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//                 Are you sure want to delete the department "{department?.name}"?
//             </Modal.Body>
//             <Modal.Footer>
//                 <Button variant="secondary" onClick={onHide}>
//                     Cancel
//                 </Button>
//                 <Button variant="danger" onClick={() => onDelete(department.id)}>
//                     Yes, Delete
//                 </Button>
//             </Modal.Footer>
//         </Modal>
//     );
// };

// export default DeleteDepartmentModal;



import React from 'react';

import { Modal, Button } from 'react-bootstrap';

const DeleteDepartmentModal = ({ show, onHide, department, onDelete }) => {

  return (

    <Modal show={show} onHide={onHide} centered>

      <Modal.Header closeButton>

        <Modal.Title>Delete Department</Modal.Title>

      </Modal.Header>

      <Modal.Body>

        Are you sure want to delete the department <span style={{fontWeight: "bold"}}>{department?.department_name}</span> ?

      </Modal.Body>

      <Modal.Footer>

        <Button variant="secondary" onClick={onHide}>

          Cancel

        </Button>

        <Button variant="danger" onClick={() => onDelete(department.id)}>

          Yes, Delete

        </Button>

      </Modal.Footer>

    </Modal>

  );

};

export default DeleteDepartmentModal;





