
// import React, { useEffect, useState } from "react";
// import ListView from "./ListView/ListView";
// import KanbanView from "./KanbanView/KanbanView";
// import TicketForm from "./TicketForm/TicketForm";
// import { RxHamburgerMenu } from "react-icons/rx";
// import { CiLineHeight } from "react-icons/ci";
// import "./Ticket.css";
// import Filters_Tickets from "./Filters_Tickets/Filters_Tickets";

// const Ticket = () => {
//   const [activeTab, setActiveTab] = useState("list"); // Initially set to 'list'
//   const [showAddNewTicket, setShowAddNewTicket] = useState(false); // State to control Add_newTicket display
//   const [tickets, setTickets] = useState([]); // State to store tickets
//   const [item, setItem] = useState(null);

//   useEffect(() => {
//     // Retrieve the item from sessionStorage
//     const storedItem = sessionStorage.getItem("TicketSystemSuperRoleType");
//     if (storedItem) {
//       setItem(storedItem); // Update state if the item exists
//     }
//   }, []);
//   // Function to handle tab clicks
//   const handleTabClick = (tab) => {
//     setActiveTab(tab);
//     setShowAddNewTicket(false); // Reset to hide Add_newTicket when switching tabs
//   };

//   // Function to add a new ticket
//   const addNewTicket = (newTicket) => {
//     setTickets((prevTickets) => [newTicket, ...prevTickets]);
//     setShowAddNewTicket(false); // Hide the form after adding the ticket
//   };

//   return (
//     <div className="container-fluid">
//       <div className="row">
//         <div className="col-12 col-md-8 left-side-col">
//           <div className="ticket">
//             <div className="tab-buttons">
//               <div className="btn-view-tickets">
//                 <button
//                   className={activeTab === "list" ? "active" : ""}
//                   onClick={() => handleTabClick("list")}
//                 >
//                   <div className="spacing-between-btn">
//                     <RxHamburgerMenu /> List
//                   </div>
//                 </button>
//                 <button
//                   className={activeTab === "kanban" ? "active" : ""}
//                   onClick={() => handleTabClick("kanban")}
//                 >
//                   <CiLineHeight /> Kanban View
//                 </button>
//               </div>

//             </div>

//             {/* Tab Content */}
//             <div className="tab-content">
//               {showAddNewTicket ? (
//                 <TicketForm
//                   onClose={() => setShowAddNewTicket(false)}
//                   addNewTicket={addNewTicket}
//                 />
//               ) : (
//                 <>
//                   {activeTab === "list" && <ListView ticketsData={tickets} />}
//                   {activeTab === "kanban" && <KanbanView />}
//                 </>
//               )}
//             </div>
//           </div>
//         </div>

//         <div className="col-12 col-md-4">
//           <div className="filter-Ticket_op">
//             <Filters_Tickets />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Ticket;


import React, { useEffect, useState } from "react";
import ListView from "./ListView/ListView";
import KanbanView from "./KanbanView/KanbanView";
import TicketForm from "./TicketForm/TicketForm";
import Filters_Tickets from "./Filters_Tickets/Filters_Tickets"
import { FiFilter, FiFileText, FiFile } from "react-icons/fi"; // Import filter and file icons
import { RxHamburgerMenu } from "react-icons/rx";
import { CiLineHeight } from "react-icons/ci";
import jsPDF from "jspdf"; // Import jsPDF for PDF functionality
import * as XLSX from "xlsx"; // Import xlsx for Excel functionality
import "./Ticket.css";

const Ticket = () => {
  const [activeTab, setActiveTab] = useState("list"); // Initially set to 'list'
  const [showAddNewTicket, setShowAddNewTicket] = useState(false); // State to control Add_newTicket display
  const [showFilters, setShowFilters] = useState(false); // State to control Filters modal
  const [activeExport, setActiveExport] = useState(null); // State to track active export (PDF or Excel)
  const [tickets, setTickets] = useState([]); // State to store tickets
  const [item, setItem] = useState(null);

  useEffect(() => {
    // Retrieve the item from sessionStorage
    const storedItem = sessionStorage.getItem("TicketSystemSuperRoleType");
    if (storedItem) {
      setItem(storedItem); // Update state if the item exists
    }
  }, []);

  // Function to handle tab clicks
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setShowAddNewTicket(false); // Reset to hide Add_newTicket when switching tabs
    setShowFilters(false); // Reset to hide Filters modal when switching tabs
    setActiveExport(null); // Reset active export state when tab changes
  };

  // Function to add a new ticket
  const addNewTicket = (newTicket) => {
    setTickets((prevTickets) => [newTicket, ...prevTickets]);
    setShowAddNewTicket(false); // Hide the form after adding the ticket
  };

  // Function to handle PDF download
  const handlePDFDownload = () => {
    const doc = new jsPDF();
    doc.text("Ticket List", 10, 10);
    
    // Example: Add ticket data to PDF
    tickets.forEach((ticket, index) => {
      doc.text(`${index + 1}. ${ticket.title}`, 10, 20 + index * 10);
    });

    doc.save("tickets.pdf"); // Save the PDF file
  };

  // Function to handle Excel download
  const handleExcelDownload = () => {
    const ws = XLSX.utils.json_to_sheet(tickets); // Convert tickets array to worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Tickets"); // Append sheet to workbook
    XLSX.writeFile(wb, "tickets.xlsx"); // Save the Excel file
  };

  // Function to handle export button click
  const handleExportClick = (type) => {
    setActiveExport(type);
    if (type === "PDF") {
      handlePDFDownload(); // Call PDF download function
    } else if (type === "Excel") {
      handleExcelDownload(); // Call Excel download function
    }
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 left-side-col">
          <div className="ticket">
            <div className="tab-buttons">
              <div className="btn-view-tickets">
                <button
                  className={activeTab === "list" ? "active" : ""}
                  onClick={() => handleTabClick("list")}
                >
                  <div className="spacing-between-btn">
                    <RxHamburgerMenu /> List
                  </div>
                </button>
                <button
                  className={activeTab === "kanban" ? "active" : ""}
                  onClick={() => handleTabClick("kanban")}
                >
                  <CiLineHeight /> Kanban View
                </button>
              </div>
              <div className="btn-view-tickets">
                <button
                  className={activeExport === "PDF" ? "active" : ""}
                  onClick={() => handleExportClick("PDF")}
                >
                  <FiFileText className="mr-1" /> PDF
                </button>
                <button
                  className={activeExport === "Excel" ? "active" : ""}
                  onClick={() => handleExportClick("Excel")}
                >
                  <FiFile className="mr-1" /> Excel
                </button>
                <button
                  className={showFilters ? "active" : ""}
                  onClick={() => {
                    setShowFilters(!showFilters);
                    setActiveExport(null); // Reset export active status when filter is clicked
                  }}
                >
                  <FiFilter className="mr-1" /> Filters
                </button>
              </div>
            </div>

            {/* Tab Content */}
            <div className="tab-content">
              {showAddNewTicket ? (
                <TicketForm
                  onClose={() => setShowAddNewTicket(false)}
                  addNewTicket={addNewTicket}
                />
              ) : (
                <>
                  {activeTab === "list" && <ListView ticketsData={tickets} />}
                  {activeTab === "kanban" && <KanbanView />}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Filter Modal */}
      {showFilters && (
        <Filters_Tickets onClose={() => setShowFilters(false)} />
      )}
    </div>
  );
};

export default Ticket;