



import React, { useState } from "react";
import './Ticket_Scenario.css';
import Reply from "./Reply/Reply";
import AddNote from "./AddNote/AddNote";
import Forward from "./Forward/Forward";
import Delete from "./Delete/Delete";
import Close from "./Close/Close"; // Import the CloseModal
import { useParams } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";


const Ticket_Scenario = () => {
  const { ticketId } = useParams();
  const { ticketMail } = useParams();

  const [activeTab, setActiveTab] = useState("reply");
  const [showCloseModal, setShowCloseModal] = useState(false); // State for modal visibility
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for Delete modal visibility


  const renderTabContent = () => {
    switch (activeTab) {
      case "reply":
        return <Reply ticketMail={ticketMail}  ticketId={ticketId} />;
      case "addNote":
        return <AddNote ticketMail={ticketMail}  ticketId={ticketId} />;
      case "forward":
        return <Forward ticketMail={ticketMail} ticketId={ticketId} />;
      default:
        return null;
    }
  };

  const handleCloseTabClick = () => {
    setShowCloseModal(true); // Show the modal when "Close" is clicked
  };

  const handleCloseModal = () => {
    setShowCloseModal(false); // Hide the modal
  };

  const handleDeleteTabClick = () => {
    setShowDeleteModal(true); // Show the delete modal
  };

  const handleDeleteModal = () => {
    setShowDeleteModal(false); // Hide the delete modal
  };

  const handleDeleteTicket = () => {
    // Implement the ticket deletion logic here (API call, state update, etc.)
    console.log("Ticket deleted");
    setShowDeleteModal(false); // Hide modal after deleting
  };

  return (
    <div className="container container-ticketScenario">
      <div className="tab-buttons-ticketScenario">
      <button
          onClick={() => setActiveTab("back")}
          className={activeTab === "back" ? "active" : ""}
        >
          <IoArrowBack /> Back
        </button>

<div className="">
        <button
          onClick={() => setActiveTab("reply")}
          className={activeTab === "reply" ? "active" : ""}
        >
          Reply
        </button>
        <button
          onClick={() => setActiveTab("addNote")}
          className={activeTab === "addNote" ? "active" : ""}
        >
          Add Note
        </button>
        <button
          onClick={() => setActiveTab("forward")}
          className={activeTab === "forward" ? "active" : ""}
        >
          Forward
        </button>
        <button
          onClick={handleCloseTabClick} // Trigger modal on click
          className={activeTab === "close" ? "active" : ""}
        >
          Close
        </button>
        <button
          onClick={handleDeleteTabClick} // Trigger delete modal on click
          className={activeTab === "delete" ? "active" : ""}
        >
          Delete
        </button>
      </div>
      </div>
      <div className="tab-content">{renderTabContent()}</div>

      {/* Render the CloseModal */}
      <Close show={showCloseModal} handleClose={handleCloseModal} ticketId={ticketId} />
    {/* Render DeleteModal */}
    <Delete
        show={showDeleteModal}
        handleClose={handleDeleteModal}
        onDelete={handleDeleteTicket} // Pass the delete function
        ticketId={ticketId}
      />
    </div>
  );
};

export default Ticket_Scenario;

