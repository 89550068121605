
// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import ForgotPasswordModal from "../ForgotPasswordModal/ForgotPasswordModal";
// import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
// import "react-toastify/dist/ReactToastify.css"; // Import toast CSS
// import "./../../Credentials/Credentials.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
// import Loader from "../../Loader/Loader";

// const Login = () => {
//   const [showPassword, setShowPassword] = useState(false);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [error, setError] = useState("");
//   const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);

//   const validatePassword = (password) => {
//     const passwordPattern =
//       /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
//     return passwordPattern.test(password);
//   };

//   const validateEmail = (email) => {
//     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailPattern.test(email);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!email || !password) {
//       setError("All fields are required.");
//       toast.error("All fields are required."); // Show toast error
//       return;
//     }

//     if (!validateEmail(email)) {
//       setError("Please enter a valid email address.");
//       toast.error("Please enter a valid email address."); // Show toast error
//       return;
//     }

//     if (!validatePassword(password)) {
//       setError(
//         "Password must be at \least 8 characters long, with at least one uppercase letter, one number, and one special character."
//       );
//       toast.error(
//         "Password must be at least 8 characters long, with at least one uppercase letter, one number, and one special character."
//       ); // Show toast error
//       return;
//     }

//     setError("");

//     try {
//       setLoading(true);
//       const response = await axios.post(
//         "http://ticketsystem.techfluxsolutions.com/superadminLogin",
//         {
//           superadmin_email: email,
//           superadmin_password: password,
//         }
//       );

//       const token = response?.data?.token;
//       if (token) {
//         sessionStorage.setItem("TicketSystemSuperAdminToken", token);
//         sessionStorage.setItem("isSuperAdminLoggedIn", "true");
//         navigate("/dashboard");
//       } else {
//         setError("Login failed. Please try again.");
//         toast.error("Login failed. Please try again."); // Show toast error
//       }

//       if (response.data) {
//         navigate("/dashboard");
//       }
//     } catch (err) {
//       setLoading(false);
//       setError("Invalid email or password. Please try again.");
//       toast.error("Invalid email or password. Please try again."); // Show toast error
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleSignupClick = () => {
//     navigate("/signup");
//   };

//   const handleForgotPasswordClick = () => {
//     setShowForgotPasswordModal(true);
//   };

//   const handleCloseForgotPasswordModal = () => {
//     setShowForgotPasswordModal(false);
//   };

//   if (loading) {
//     return <Loader />;
//   }

//   return (
//     <div className="container container-credentials">
//       <ToastContainer /> {/* Add ToastContainer for displaying toasts */}
//       <div className="row">
//         <div className="row-Credentials">
//           <div className="col-12 col-md-6 inner-container-credentials">
//             <p className="heading-credentials">Login to support portal</p>
//             <form onSubmit={handleSubmit}>
//               <div className="mb-3">
//                 <label htmlFor="email" className="form-label form-label-color">
//                   Email
//                 </label>
//                 <input
//                   type="email"
//                   className="form-control form-credentials"
//                   id="email"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                 />
//               </div>

//               <div className="mb-3 position-relative">
//                 <label
//                   htmlFor="password"
//                   className="form-label form-label-color"
//                 >
//                   Your Password
//                 </label>
//                 <input
//                   type={showPassword ? "text" : "password"}
//                   className="form-control form-credentials"
//                   id="password"
//                   value={password}
//                   onChange={(e) => setPassword(e.target.value)}
//                 />
//                 <span
//                   className="password-toggle"
//                   onClick={() => setShowPassword(!showPassword)}
//                 >
//                   {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
//                 </span>
//               </div>

//               {error && <div className="text-danger mb-3">{error}</div>}

//               <div>
//                 <button type="submit" className="btn btn-credentials">
//                   Login
//                 </button>
//                 <div className="flex-credentials">
//                   <p
//                     className="user-login-credentials"
//                     onClick={handleSignupClick}
//                     style={{ cursor: "pointer" }}
//                   >
//                     New user? Signup
//                   </p>
//                   <p
//                     className="user-login-credentials"
//                     onClick={handleForgotPasswordClick}
//                     style={{ cursor: "pointer" }}
//                   >
//                     Forgot Password?
//                   </p>
//                 </div>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>

//       {/* Forgot Password Modal */}
//       <ForgotPasswordModal
//         show={showForgotPasswordModal}
//         handleClose={handleCloseForgotPasswordModal}
//       />
//     </div>
//   );
// };

// export default Login;



import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ForgotPasswordModal from "../ForgotPasswordModal/ForgotPasswordModal";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import toast CSS
import "./../../Credentials/Credentials.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Loader from "../../Loader/Loader";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // const validatePassword = (password) => {
  //   const passwordPattern =
  //     /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
  //   return passwordPattern.test(password);
  // };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError("All fields are required.");
      toast.error("All fields are required."); // Show toast error
      return;
    }

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      toast.error("Please enter a valid email address."); // Show toast error
      return;
    }

    // if (!validatePassword(password)) {
    //   setError(
    //     "Password must be at \least 8 characters long, with at least one uppercase letter, one number, and one special character."
    //   );
    //   toast.error(
    //     "Password must be at least 8 characters long, with at least one uppercase letter, one number, and one special character."
    //   ); // Show toast error
    //   return;
    // }

    // setError("");

    try {
      setLoading(true);
      const response = await axios.post(
        "https://ticketsystem.techfluxsolutions.com/agents/login",
        {
          agent_email: email,
          agent_password: password,
        }
      );
      console.log("Super Admin:",response?.data?.role_type)
      const role_type =response?.data?.role_type
      sessionStorage.setItem("TicketSystemSuperRoleType", role_type);

      const token = response?.data?.token;
      if (token) {
        sessionStorage.setItem("TicketSystemSuperAdminToken", token);
        sessionStorage.setItem("isSuperAdminLoggedIn", "true");
        navigate("/dashboard");
      } else {
        setError("Login failed. Please try again.");
        toast.error("Login failed. Please try again."); // Show toast error
      }

      console.log("Login Response",response)
      if (response.data.response) {
        toast.success("Successfully Login!")
        navigate("/dashboard");
      }
      else{
        toast.error(response.data.response.error_msg)
      }
    } catch (err) {
      setLoading(false);
      setError("Invalid email or password. Please try again.");
      toast.error("Invalid email or password. Please try again."); // Show toast error
    } finally {
      setLoading(false);
    }
  };

  const handleSignupClick = () => {
    navigate("/signup");
  };

  const handleForgotPasswordClick = () => {
    setShowForgotPasswordModal(true);
  };

  const handleCloseForgotPasswordModal = () => {
    setShowForgotPasswordModal(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="container container-credentials">
      <ToastContainer /> {/* Add ToastContainer for displaying toasts */}
      <div className="row">
        <div className="row-Credentials">
          <div className="col-12 col-md-6 inner-container-credentials">
            <p className="heading-credentials">Login to support portal</p>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label form-label-color">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control form-credentials"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="mb-3 position-relative">
                <label
                  htmlFor="password"
                  className="form-label form-label-color"
                >
                Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control form-credentials"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="password-toggle"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                </span>
              </div>

              {error && <div className="text-danger mb-3">{error}</div>}

              <div>
                <button type="submit" className="btn btn-credentials">
                  Login
                </button>
                <div className="flex-credentials">
                  {/* <p
                    className="user-login-credentials"
                    onClick={handleSignupClick}
                    style={{ cursor: "pointer" }}
                  >
                    New user? Signup
                  </p> */}
                  <p
                    className="user-login-credentials"
                    onClick={handleForgotPasswordClick}
                    style={{ cursor: "pointer" }}
                  >
                    Forgot Password?
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Forgot Password Modal */}
      <ForgotPasswordModal
        show={showForgotPasswordModal}
        handleClose={handleCloseForgotPasswordModal}
      />
    </div>
  );
};

export default Login;
