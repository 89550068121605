
import React, { useState, useEffect } from 'react';

import { Modal, Button, Form } from 'react-bootstrap';

import axios from 'axios';

import { toast } from 'react-toastify';

import Loader from './../../../Loader/Loader';

const EditRoleModal = ({ show, onHide, role, fetchRoles }) => {

  const [name, setName] = useState('');

  const [description, setDescription] = useState('');

  const [agentName, setAgentName] = useState('');

  const [loading, setLoading] = useState(false);

  useEffect(() => {

    if (role) {

      setName(role.role_name || '');

      setDescription(role.role_description || '');

      setAgentName(role.agent_type_name || '');

    }

  }, [role]);

  const handleSubmit = async () => {

    setLoading(true);

    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

    const payload = {

      role_id: role?.role_id,

      role_name: name || role?.role_name,

      role_description: description || role?.role_description,

      // agent_type_name: agentName || role?.agent_type_name,

      agent_type_id: role ? role.agent_type_id : null,

    };

    try {

      const response = await axios.post(

        "https://ticketsystem.techfluxsolutions.com/update_roles",

        payload,

        {

          headers: {

            Authorization: `Bearer ${token}`,

          },

        }

      );

      if (response.data.response) {

        toast.success("Role updated successfully");

        // fetchRoles();

        fetchRoles();

        fetchRoles();

        onHide();

      } else {

        toast.error("Failed to update role");

      }

    } catch (error) {

      console.error("API error:", error);

      toast.error("Failed to update role");

    } finally {

      setLoading(false);

    }

  };

  return (

    <Modal show={show} onHide={onHide} centered>

      <Modal.Header closeButton>

        <Modal.Title>Edit Role</Modal.Title>

      </Modal.Header>

      <Modal.Body>

        {loading ? (

          <Loader />

        ) : (

          <Form>

            <Form.Group controlId="editRoleName">

              <Form.Label>Role Name</Form.Label>

              <Form.Control

                type="text"

                className='form-control-department'

                value={name}

                onChange={(e) => setName(e.target.value)}

              />

            </Form.Group>

            <Form.Group controlId="editRoleDescription">

              <Form.Label>Description</Form.Label>

              <Form.Control

                as="textarea"

                rows={3}

                className='form-control-department'

                value={description}

                onChange={(e) => setDescription(e.target.value)}

              />

            </Form.Group>

            <Form.Group controlId="editAgentType">

              <Form.Label>Staff Type</Form.Label>

              <Form.Control

                type="text"

                value={agentName}

                disabled

              />

            </Form.Group>

          </Form>

        )}

      </Modal.Body>

      <Modal.Footer>

        <Button variant="secondary" onClick={onHide}>Cancel</Button>

        <Button variant="primary" onClick={handleSubmit} disabled={loading}>

          {loading ? 'Saving...' : 'Save Changes'}

        </Button>

      </Modal.Footer>

    </Modal>

  );

};

export default EditRoleModal;

















