import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../Loader/Loader';

const CreateRoleModal = ({ show, onHide, onCreate,fetchRoles }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [agentType, setAgentType] = useState('');
    const [agentTypes, setAgentTypes] = useState([]);
    const [loading, setLoading] = useState(false);

    // Function to fetch agent types
    const fetchAgentTypes = async () => {
        try {
            const response = await axios.get('https://ticketsystem.techfluxsolutions.com/getAgentTypes', {
                headers: {
                    'Authorization': 'Bearer TicketSystemSuperAdminToken'
                }
            });
            if (response.data.response) {
                setAgentTypes(response.data.data);
                // fetchAgentTypes();
            } else {
                toast.error('Failed to retrieve agent types');
            }
        } catch (error) {
            console.error('Error fetching agent types:', error);
            toast.error('An error occurred while fetching agent types.');
        }
    };

    // Fetch agent types from API when the modal is shown
    useEffect(() => {
        
            fetchAgentTypes();
    
    },[]);

    // Reset form fields when the modal closes
    useEffect(() => {
        if (!show) {
            setName('');
            setDescription('');
            setAgentType('');
        }
    }, [show]);

    const handleSubmit = async () => {
        // Validate required fields
        if (!name) {
            toast.error('Role Name is required.');
            return;
        }

        if (!agentType) {
            toast.error('Please select an Agent Type.');
            return;
        }

        setLoading(true);

        try {
            // Find the selected agent type ID
            const selectedAgentType = agentTypes.find(type => type.agent_type_name === agentType);

            // Prepare the data for the API request
            const data = {
                role_name: name,
                role_description: description,
                agent_type_id: selectedAgentType ? selectedAgentType.agent_type_id : null,
            };

            const response = await axios.post('https://ticketsystem.techfluxsolutions.com/add_roles', data, {
                headers: {
                    'Authorization': 'Bearer TicketSystemSuperAdminToken',
                    'Content-Type': 'application/json'
                }
            });

            console.log("Role Modal", response?.data);
            if (response?.status === 200) {
                toast.success('Role created successfully');
                onCreate(data); 
                fetchRoles(); 
                onHide(); 
            } else {
                toast.error('Failed to create role');
            }
        } catch (error) {
            console.error('Error creating role:', error);
            toast.error('An error occurred while creating the role.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <Loader/> // Show custom loader while loading
                    ) : (
                        <Form>
                            {/* Role Name */}
                            <Form.Group controlId="roleName">
                                <Form.Label>Role Name <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    className='form-control-department'
                                    placeholder="Enter role name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            {/* Description */}
                            <Form.Group controlId="roleDescription">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    className='form-control-department'
                                    placeholder="Enter role description (optional)"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Form.Group>

                            {/* Agent Type */}
                            <Form.Group controlId="agentType">
                                <Form.Label>Staff Type <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    as="select"
                                    className='form-control-department'
                                    value={agentType}
                                    onChange={(e) => setAgentType(e.target.value)}
                                    required
                                >
                                    <option value="">Select Staff Type</option>
                                    {agentTypes.map((type) => (
                                        <option key={type.agent_type_id} value={type.agent_type_name}>
                                            {type.agent_type_name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <p className="text-muted mt-2">
                                Note: Staff Role can't be edited once selected.
                            </p>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide} disabled={loading}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmit} disabled={loading}>
                        {loading ? <Spinner animation="border" size="sm" /> : 'Create'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* ToastContainer for toast messages */}
            {/* <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar newestOnTop closeOnClick pauseOnHover /> */}
        </>
    );
};

export default CreateRoleModal;
