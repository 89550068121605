// import React from "react";
// import { FaChevronDown } from "react-icons/fa"; 
// import "./Filters_Tickets.css"; 

// const Filters_Tickets = () => {
 
//   const agentOptions = ["Select an agent", "Agent 1", "Agent 2", "Agent 3"];
//   const groupOptions = ["Select a group", "Group 1", "Group 2", "Group 3"];
//   const createdOptions = [
//     "Select a creation date",
//     "Last 24 hours",
//     "Last 7 days",
//     "Last 30 days",
//     "Custom",
//   ];
//   const closedAtOptions = [
//     "Select a closing date",
//     "Last 24 hours",
//     "Last 7 days",
//     "Last 30 days",
//     "Custom",
//   ];
//   const resolvedAtOptions = [
//     "Select a resolution date",
//     "Last 24 hours",
//     "Last 7 days",
//     "Last 30 days",
//     "Custom",
//   ];
//   const resolutionDueByOptions = [
//     "Select a due date",
//     "Next 24 hours",
//     "Next 7 days",
//     "Next 30 days",
//     "Custom",
//   ];
//   const firstResponseDueByOptions = [
//     "Select a due date",
//     "Next 1 hour",
//     "Next 3 hours",
//     "Next 12 hours",
//     "Next 24 hours",
//     "Custom",
//   ];
//   const nextResponseDueByOptions = [
//     "Select a due date",
//     "Next 1 hour",
//     "Next 3 hours",
//     "Next 12 hours",
//     "Next 24 hours",
//     "Custom",
//   ];
//   const skillsIncludeOptions = [
//     "Select skills",
//     "Skill 1",
//     "Skill 2",
//     "Skill 3",
//     "Skill 4",
//   ];
//   const statusIncludeOptions = [
//     "Select status",
//     "Open",
//     "Pending",
//     "Resolved",
//     "Closed",
//   ];

//   // Additional fields options
//   const prioritiesIncludeOptions = ["Select priority", "High", "Medium", "Low"];
//   const typesIncludeOptions = ["Select type", "Issue", "Task", "Feature"];
//   const sourcesIncludeOptions = [
//     "Select source",
//     "Email",
//     "Phone",
//     "Chat",
//     "Form",
//   ];
//   const tagsOptions = ["Select tag", "Urgent", "Customer Feedback", "Bug"];
//   const companiesIncludeOptions = [
//     "Select company",
//     "Company A",
//     "Company B",
//     "Company C",
//   ];
//   const contactsIncludeOptions = [
//     "Select contact",
//     "Contact 1",
//     "Contact 2",
//     "Contact 3",
//   ];
//   const productsIncludeOptions = [
//     "Select product",
//     "Product A",
//     "Product B",
//     "Product C",
//   ];

//   // Function to map options
//   const renderOptions = (options) => {
//     return options.map((option, index) => (
//       <option key={index} value={option}>
//         {option}
//       </option>
//     ));
//   };

//   return (
//     <>
//       <div className="filter-Ticket container mb-5">
//         {/* Agent Dropdown */}
//         <div className="form-group position-relative">
//           <label htmlFor="agentDropdown">Agent</label>
//           <select className="form-control custom-dropdown" id="agentDropdown">
//             {renderOptions(agentOptions)}
//           </select>
//           <FaChevronDown className="dropdown-icon" />
//         </div>

//         {/* Group Dropdown */}
//         <div className="form-group position-relative mt-3">
//           <label htmlFor="groupDropdown">Group</label>
//           <select className="form-control custom-dropdown" id="groupDropdown">
//             {renderOptions(groupOptions)}
//           </select>
//           <FaChevronDown className="dropdown-icon" />
//         </div>

//         {/* Created Dropdown */}
//         <div className="form-group position-relative mt-3">
//           <label htmlFor="createdDropdown">Created</label>
//           <select className="form-control custom-dropdown" id="createdDropdown">
//             {renderOptions(createdOptions)}
//           </select>
//           <FaChevronDown className="dropdown-icon" />
//         </div>

//         {/* Closed At Dropdown */}
//         <div className="form-group position-relative mt-3">
//           <label htmlFor="closedAtDropdown">Closed At</label>
//           <select
//             className="form-control custom-dropdown"
//             id="closedAtDropdown"
//           >
//             {renderOptions(closedAtOptions)}
//           </select>
//           <FaChevronDown className="dropdown-icon" />
//         </div>

//         {/* Resolved At Dropdown */}
//         <div className="form-group position-relative mt-3">
//           <label htmlFor="resolvedAtDropdown">Resolved At</label>
//           <select
//             className="form-control custom-dropdown"
//             id="resolvedAtDropdown"
//           >
//             {renderOptions(resolvedAtOptions)}
//           </select>
//           <FaChevronDown className="dropdown-icon" />
//         </div>

//         {/* Resolution Due By Dropdown */}
//         <div className="form-group position-relative mt-3">
//           <label htmlFor="resolutionDueByDropdown">Resolution Due By</label>
//           <select
//             className="form-control custom-dropdown"
//             id="resolutionDueByDropdown"
//           >
//             {renderOptions(resolutionDueByOptions)}
//           </select>
//           <FaChevronDown className="dropdown-icon" />
//         </div>

//         {/* First Response Due By Dropdown */}
//         <div className="form-group position-relative mt-3">
//           <label htmlFor="firstResponseDueByDropdown">
//             First Response Due By
//           </label>
//           <select
//             className="form-control custom-dropdown"
//             id="firstResponseDueByDropdown"
//           >
//             {renderOptions(firstResponseDueByOptions)}
//           </select>
//           <FaChevronDown className="dropdown-icon" />
//         </div>

//         {/* Next Response Due By Dropdown */}
//         <div className="form-group position-relative mt-3">
//           <label htmlFor="nextResponseDueByDropdown">
//             Next Response Due By
//           </label>
//           <select
//             className="form-control custom-dropdown"
//             id="nextResponseDueByDropdown"
//           >
//             {renderOptions(nextResponseDueByOptions)}
//           </select>
//           <FaChevronDown className="dropdown-icon" />
//         </div>

//         {/* Skills Include Dropdown */}
//         <div className="form-group position-relative mt-3">
//           <label htmlFor="skillsIncludeDropdown">Skills Include</label>
//           <select
//             className="form-control custom-dropdown"
//             id="skillsIncludeDropdown"
//           >
//             {renderOptions(skillsIncludeOptions)}
//           </select>
//           <FaChevronDown className="dropdown-icon" />
//         </div>

//         {/* Status Include Dropdown */}
//         <div className="form-group position-relative mt-3">
//           <label htmlFor="statusIncludeDropdown">Status Include</label>
//           <select
//             className="form-control custom-dropdown"
//             id="statusIncludeDropdown"
//           >
//             {renderOptions(statusIncludeOptions)}
//           </select>
//           <FaChevronDown className="dropdown-icon" />
//         </div>

//         {/* Priorities Include Dropdown */}
//         <div className="form-group position-relative mt-3">
//           <label htmlFor="prioritiesIncludeDropdown">Priorities Include</label>
//           <select
//             className="form-control custom-dropdown"
//             id="prioritiesIncludeDropdown"
//           >
//             {renderOptions(prioritiesIncludeOptions)}
//           </select>
//           <FaChevronDown className="dropdown-icon" />
//         </div>

//         {/* Types Include Dropdown */}
//         <div className="form-group position-relative mt-3">
//           <label htmlFor="typesIncludeDropdown">Types Include</label>
//           <select
//             className="form-control custom-dropdown"
//             id="typesIncludeDropdown"
//           >
//             {renderOptions(typesIncludeOptions)}
//           </select>
//           <FaChevronDown className="dropdown-icon" />
//         </div>

//         {/* Sources Include Dropdown */}
//         <div className="form-group position-relative mt-3">
//           <label htmlFor="sourcesIncludeDropdown">Sources Include</label>
//           <select
//             className="form-control custom-dropdown"
//             id="sourcesIncludeDropdown"
//           >
//             {renderOptions(sourcesIncludeOptions)}
//           </select>
//           <FaChevronDown className="dropdown-icon" />
//         </div>

//         {/* Tags Dropdown */}
//         <div className="form-group position-relative mt-3">
//           <label htmlFor="tagsDropdown">Tags</label>
//           <select className="form-control custom-dropdown" id="tagsDropdown">
//             {renderOptions(tagsOptions)}
//           </select>
//           <FaChevronDown className="dropdown-icon" />
//         </div>

//         {/* Companies Include Dropdown */}
//         <div className="form-group position-relative mt-3">
//           <label htmlFor="companiesIncludeDropdown">Companies Include</label>
//           <select
//             className="form-control custom-dropdown"
//             id="companiesIncludeDropdown"
//           >
//             {renderOptions(companiesIncludeOptions)}
//           </select>
//           <FaChevronDown className="dropdown-icon" />
//         </div>

//         {/* Contacts Include Dropdown */}
//         <div className="form-group position-relative mt-3">
//           <label htmlFor="contactsIncludeDropdown">Contacts Include</label>
//           <select
//             className="form-control custom-dropdown"
//             id="contactsIncludeDropdown"
//           >
//             {renderOptions(contactsIncludeOptions)}
//           </select>
//           <FaChevronDown className="dropdown-icon" />
//         </div>

//         {/* Products Include Dropdown */}
//         <div className="form-group position-relative mt-3">
//           <label htmlFor="productsIncludeDropdown">Products Include</label>
//           <select
//             className="form-control custom-dropdown"
//             id="productsIncludeDropdown"
//           >
//             {renderOptions(productsIncludeOptions)}
//           </select>
//           <FaChevronDown className="dropdown-icon" />
//         </div>

//         <button className="btn-filters">Apply</button>
//         <footer className="footer-filters">
//       </footer>
//       </div>
//     </>
 
//   );
// };

// export default Filters_Tickets;


import React from "react";
import { FaChevronDown } from "react-icons/fa";
import "./Filters_Tickets.css";

const Filters_Tickets = ({ onClose }) => {
  const createdOptions = [
    "Select a creation date",
    "Last 24 hours",
    "Last 7 days",
    "Last 30 days",
    "Custom",
  ];
  const closedAtOptions = [
    "Select a closing date",
    "Last 24 hours",
    "Last 7 days",
    "Last 30 days",
    "Custom",
  ];
  const resolvedAtOptions = [
    "Select a resolution date",
    "Last 24 hours",
    "Last 7 days",
    "Last 30 days",
    "Custom",
  ];
  const prioritiesIncludeOptions = ["Select priority", "High", "Medium", "Low"];
  const typesIncludeOptions = ["Select type", "Issue", "Task", "Feature"];

  const renderOptions = (options) => {
    return options.map((option, index) => (
      <option key={index} value={option}>
        {option}
      </option>
    ));
  };

  return (
    <div className="filters-modal-overlay mt-4">
      <div className="filters-modal-content">
        <h3 className="filters-modal-title">Filters</h3>
        
        <div className="filters-form-group">
          <label htmlFor="createdDropdown">Created</label>
          <div className="filters-select-wrapper">
            <select className="filters-select" id="createdDropdown">
              {renderOptions(createdOptions)}
            </select>
            <FaChevronDown className="filters-dropdown-icon" />
          </div>
        </div>

        <div className="filters-form-group">
          <label htmlFor="closedAtDropdown">Closed At</label>
          <div className="filters-select-wrapper">
            <select className="filters-select" id="closedAtDropdown">
              {renderOptions(closedAtOptions)}
            </select>
            <FaChevronDown className="filters-dropdown-icon" />
          </div>
        </div>

        <div className="filters-form-group">
          <label htmlFor="resolvedAtDropdown">Resolved At</label>
          <div className="filters-select-wrapper">
            <select className="filters-select" id="resolvedAtDropdown">
              {renderOptions(resolvedAtOptions)}
            </select>
            <FaChevronDown className="filters-dropdown-icon" />
          </div>
        </div>

        <div className="filters-form-group">
          <label htmlFor="prioritiesIncludeDropdown">Priorities Include</label>
          <div className="filters-select-wrapper">
            <select className="filters-select" id="prioritiesIncludeDropdown">
              {renderOptions(prioritiesIncludeOptions)}
            </select>
            <FaChevronDown className="filters-dropdown-icon" />
          </div>
        </div>

        <div className="filters-form-group">
          <label htmlFor="typesIncludeDropdown">Types Include</label>
          <div className="filters-select-wrapper">
            <select className="filters-select" id="typesIncludeDropdown">
              {renderOptions(typesIncludeOptions)}
            </select>
            <FaChevronDown className="filters-dropdown-icon" />
          </div>
        </div>

        <div className="filters-modal-actions">
          <button className="btn-create-dept" style={{padding:"0px 14px"}}>Apply</button>
          <button className="filters-close-btn" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filters_Tickets;