// import React from 'react';
// import { Modal, Button } from 'react-bootstrap';

// const DeleteRoleModal = ({ show, onHide, role, onDelete }) => {
//     const handleDelete = () => {
//         if (role) {
//             console.log("Deleting role with ID:", role.id); // Log the ID being passed
//             onDelete(role.id);
//         }
//     };

//     return (
//         <Modal show={show} onHide={onHide} centered>
//             <Modal.Header closeButton>
//                 <Modal.Title>Delete Role</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//                 <p>
//                     Are you sure you want to delete the role <strong>{role?.role_name}</strong>?
//                     This action cannot be undone.
//                 </p>
//             </Modal.Body>
//             <Modal.Footer>
//                 <Button variant="secondary" onClick={onHide}>
//                     Cancel
//                 </Button>
//                 <Button variant="danger" onClick={handleDelete}>
//                     Delete
//                 </Button>
//             </Modal.Footer>
//         </Modal>
//     );
// };

// export default DeleteRoleModal;




import React from 'react';

import { Modal, Button } from 'react-bootstrap';

const DeleteRoleModal = ({ show, onHide, role, onDelete , fetchRoles}) => {

 const handleDelete = () => {

  onDelete(role.id);

 };

 return (

  <Modal show={show} onHide={onHide} centered>

   <Modal.Header closeButton>

    <Modal.Title>Delete Role</Modal.Title>

   </Modal.Header>

   <Modal.Body>

    Are you sure you want to delete the role "{role?.role_name}"?

   </Modal.Body>

   <Modal.Footer>

    <Button variant="secondary" onClick={onHide}>

     Cancel

    </Button>

    <Button variant="danger" onClick={handleDelete}>

     Delete

    </Button>

   </Modal.Footer>

  </Modal>

 );

};

export default DeleteRoleModal;





