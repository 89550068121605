import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./utils/scrollToTop/ScrollToTop";
import InternetChecker from "./utils/InternetChecker/InternetChecker";
import Signup from "./Pages/Credentials/Signup/Signup";
import Login from "./Pages/Credentials/Login/Login";
import LayoutComponent from "./Templates/LayoutComponent/LayoutComponent";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Ticket from "./Pages/Ticket/Ticket";
import Profile from "./Pages/Profile/Profile/Profile";
import Settings from "./Pages/Settings/Settings";
import Ticket_Scenario from "./Pages/Ticket/Ticket_Scenario/Ticket_Scenario";



function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const isLoggedIn = sessionStorage.getItem("isSuperAdminLoggedIn");
    const encryptedToken = sessionStorage.getItem("TicketSystemSuperAdminToken");

   
    if (isLoggedIn === "true" && encryptedToken) {
      setLoggedIn(true);
    }
  }, []);


  const handleLogin = () => {
    setLoggedIn(true);
  };

  const handleLogout = () => {
    setLoggedIn(false);
  };

  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    const handleOffline = () => setIsOffline(true);
    const handleOnline = () => setIsOffline(false);

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  return (
    <>
      <div className="App">
        <BrowserRouter>
          <ScrollToTop />
          {isOffline && <InternetChecker />}

          <Routes>
            {/* Redirect logged-in users from these routes */}
            {loggedIn && (
              <>
                <Route path="/user/forgot_password" element={<Navigate to="/dashboard" />} />
                <Route path="/user/create_new_account" element={<Navigate to="/dashboard" />} />
                <Route path="/user/complete_profile" element={<Navigate to="/dashboard" />} />
                <Route path="/user/reset_password" element={<Navigate to="/dashboard" />} />
              </>
            )}

            {/* Regular routes */}
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />

            {/* Top-level routes with LayoutComponent */}
            <Route element={<LayoutComponent />}>
              <Route path="/dashboard" element={<Dashboard/>} />
              <Route path="/ticket" element={<Ticket/>} />
              <Route path="/profile" element={<Profile/>} />
              <Route path="/setting" element={<Settings/>} />
              <Route path="/ticket-scenario/:ticketId/:ticketMail" element={<Ticket_Scenario/>} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
