import React, { useEffect, useRef, useState } from "react";
import "./Reply.css";
import { BsTrash } from "react-icons/bs";
import {
  BiBold,
  BiItalic,
  BiUnderline,
  BiAlignLeft,
  BiAlignMiddle,
  BiAlignRight,
  BiLink,
  BiImage,
  BiRedo,
  BiUndo,
} from "react-icons/bi";
import Select from "react-select";
import Mail_fromListViewReply from "../Mail_fromListView/Mail_fromListViewReply";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./../../../Loader/Loader";
import axios from "axios";

const CC_OPTIONS = [
  { value: "akansha@techflux.in", label: "akansha@techflux.in" },
  { value: "radhesha@techflux.in", label: "radhesha@techflux.in" },
  { value: "bhagyashri@techflux.in", label: "bhagyashri@techflux.in" },
];

const Reply = ({ ticketId,ticketMail }) => {
  
  const editorRef = useRef(null);
  const [selectionRange, setSelectionRange] = useState(null);
  const [ccOptions, setCcOptions] = useState([]);
  const [bccOptions, setBccOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const saveSelection = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      setSelectionRange(selection.getRangeAt(0));
    }
  };

  const restoreSelection = () => {
    if (selectionRange) {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(selectionRange);
    }
  };

  const handleCommand = (command, value = null) => {
    restoreSelection();
    document.execCommand(command, false, value);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = document.createElement("img");
        img.src = reader.result;
        img.style.maxWidth = "100%";
        img.style.height = "auto";
        document.execCommand("insertHTML", false, img.outerHTML);
      };
      reader.readAsDataURL(file);
    }
  };

  const sendReply = async () => {
    setIsLoading(true);
    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
  
    const messageContent = editorRef.current.innerHTML;
    const ccEmails = ccOptions.map((option) => option.value).join(",");
    const bccEmails = bccOptions.map((option) => option.value).join(",");
  
    // Create a new FormData object
    const formData = new FormData();
    formData.append("ticket_id", ticketId);
    formData.append("ticket_reply_cc", ccEmails);
    formData.append("ticket_reply_bcc", bccEmails);
    formData.append("ticket_reply_message", messageContent);
  
    try {
      const response = await fetch(
        "https://ticketsystem.techfluxsolutions.com/reply_ticket_old",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData, // Send FormData as the request body
        }
      );
  
      const result = await response.json();
      setIsLoading(false);
  
      if (response.ok && result.response) {
        toast.success("Reply sent successfully!");
        fetchData();

        // Clear input fields and editor content after successful reply
      setCcOptions([]);
      setBccOptions([]);
      editorRef.current.innerHTML = "";
      } else {
        toast.error(result.error_msg || "Failed to send reply.");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while sending the reply.");
    }
  };
  

  const [threadData, setThreadData] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

      if (!token) {
        throw new Error("No authentication token found in session storage.");
      }
      setIsLoading(true);

      const response = await axios.get(
        `https://ticketsystem.techfluxsolutions.com/getRepliesByTicketId/${ticketId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false);

      console.log("API Response:", response.data); // Log the response data

      // Extract replies and transform into an array format
      const replies = response.data.data.replies;
      const formattedReplies = Object.values(replies).map((reply) => ({
        sender: reply.ticket_reply_cc.join(", "), // Join CC addresses to display
        email: reply.ticket_reply_bcc.join(", "), // Join BCC addresses to display
        message: reply.ticket_reply_message,
        // date: new Date().toLocaleString(), // Add a placeholder for date; you can replace this with actual dates if available
        replies: [], // Placeholder for nested replies if needed
      }));

      // Set the thread data
      setThreadData(formattedReplies);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  //   useEffect(() => {
  //   fetchData();
  // }, [ticketId]);

  return (
    <div className="container reply-container">
      {isLoading && <Loader />}

      <Mail_fromListViewReply ticketId={ticketId} newReplies={threadData} />

      <div className="reply-header">
        <div className="email-info">
          <label>From:</label>
          <input
            type="text"
            placeholder="helpdesk.techflux@gmail.com"
            className="input-field-reply"
            disabled
          />
        </div>
        <div className="email-info">
          <label>To:</label>
          <input
            type="text"
            value={ticketMail}
            className="input-field-reply"
            disabled
          />
        </div>
        <div className="email-info">
          <label>CC:</label>
          <Select
            isMulti
            value={ccOptions}
            onChange={(selectedOptions) => setCcOptions(selectedOptions)}
            options={CC_OPTIONS}
            required
            placeholder="Add CC"
            className="input-field-reply"
          />
        </div>
        <div className="email-info">
          <label>BCC:</label>
          <Select
            isMulti
            value={bccOptions}
            onChange={(selectedOptions) => setBccOptions(selectedOptions)}
            options={CC_OPTIONS}
            placeholder="Add BCC"
            className="input-field-reply"
          />
        </div>
      </div>

      <div className="reply-body">
        <div
          ref={editorRef}
          contentEditable
          className="text-area"
          placeholder="Hi Google,"
          onSelect={saveSelection}
          onFocus={() => editorRef.current.focus()}
        ></div>
        <div className="toolbar">
          <BiBold title="Bold" onClick={() => handleCommand("bold")} />
          <BiItalic title="Italic" onClick={() => handleCommand("italic")} />
          <BiUnderline
            title="Underline"
            onClick={() => handleCommand("underline")}
          />
          <span className="divider"></span>
          <BiAlignLeft
            title="Align Left"
            onClick={() => handleCommand("justifyLeft")}
          />
          <BiAlignMiddle
            title="Align Center"
            onClick={() => handleCommand("justifyCenter")}
          />
          <BiAlignRight
            title="Align Right"
            onClick={() => handleCommand("justifyRight")}
          />
          <span className="divider"></span>
          <BiLink
            title="Insert Link"
            onClick={() => {
              const url = prompt("Enter URL:");
              if (url) handleCommand("createLink", url);
            }}
          />
          {/* <BiImage
            title="Insert Image"
            onClick={() => document.getElementById("image-upload").click()}
          />
          <input
            type="file"
            id="image-upload"
            accept="image/*"
            // style={{display: 'none'}}
            onChange={handleImageUpload}
          /> */}
          <span className="divider"></span>
          <BiRedo title="Redo" onClick={() => handleCommand("redo")} />
          <BiUndo title="Undo" onClick={() => handleCommand("undo")} />
        </div>
      </div>

      <div className="reply-footer">
        <button className="send-button-reply" onClick={sendReply}>
          Send
        </button>
        {/* <BsTrash className="trash-icon" title="Delete Draft" /> */}
      </div>

      {/* <ToastContainer /> */}
    </div>
  );
};

export default Reply;


