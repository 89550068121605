// import React from "react";
// import { Modal, Button } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";

// const Delete = ({ show, handleClose, onDelete ,ticketId}) => {
//   const navigate = useNavigate();

//   const handleConfirmDelete = () => {
//     onDelete(); // Call the function to delete the ticket
//     handleClose(); // Close the modal after deletion
//     navigate('/ticket'); // Redirect to the new page after deleting the ticket
//   };

//   return (
//     <Modal show={show} onHide={handleClose} centered>
//       <Modal.Header closeButton>
//         <Modal.Title>Confirm Ticket Deletion</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         Are you sure you want to delete this ticket? This action cannot be undone.
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleClose}>
//           Cancel
//         </Button>
//         <Button variant="danger" onClick={handleConfirmDelete}>
//           Delete
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default Delete;


import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Delete = ({ show, handleClose, onDelete, ticketId }) => {
  const navigate = useNavigate();

  const handleConfirmDelete = async () => {
    const token = sessionStorage.getItem("TicketSystemSuperAdminToken"); // Retrieve the token from session storage

    try {
      const response = await fetch(`https://ticketsystem.techfluxsolutions.com/users/delete_ticket/${ticketId}`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });

      if (response.ok) {
        onDelete(); // Call the function to update the UI after deletion
        handleClose(); // Close the modal after deletion
        navigate('/ticket'); // Redirect to the ticket page
      } else {
        console.error("Failed to delete ticket");
      }
    } catch (error) {
      console.error("Error deleting ticket:", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Ticket Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this ticket? This action cannot be undone.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleConfirmDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Delete;
