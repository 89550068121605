import React, { useRef, useState } from "react";
import "./Forward.css";
import { BsTrash } from "react-icons/bs";
import {
  BiBold,
  BiItalic,
  BiUnderline,
  BiAlignLeft,
  BiAlignMiddle,
  BiAlignRight,
  BiLink,
  BiImage,
  BiRedo,
  BiUndo,
} from "react-icons/bi";
import Select from "react-select";
import Mail_fromListViewForward from "../Mail_fromListView/Mail_fromListViewForward";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Loader/Loader";
import axios from "axios";

const CC_OPTIONS = [
  { value: "akansha@techflux.in", label: "akansha@techflux.in" },
  { value: "radhesha@techflux.in", label: "radhesha@techflux.in" },
  { value: "bhagyashri@techflux.in", label: "bhagyashri@techflux.in" },
];

const BCC_OPTIONS = [
  { value: "akansha@techflux.in", label: "akansha@techflux.in" },
  { value: "radhesha@techflux.in", label: "radhesha@techflux.in" },
  { value: "bhagyashri@techflux.in", label: "bhagyashri@techflux.in" },
];

const Forward = ({ ticketId, ticketMail }) => {
  const editorRef = useRef(null);
  const [selectionRange, setSelectionRange] = useState(null);
  const [ccOptions, setCcOptions] = useState([]);
  const [bccOptions, setBccOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const saveSelection = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      setSelectionRange(selection.getRangeAt(0));
    }
  };

  const restoreSelection = () => {
    if (selectionRange) {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(selectionRange);
    }
  };

  const handleCommand = (command, value = null) => {
    restoreSelection();
    document.execCommand(command, false, value);
  };

  const handleSend = async () => {
    setLoading(true);
    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
    const messageContent = editorRef.current.innerHTML;
    const ticketForwardCc = ccOptions.map((option) => option.value).join(",");
    const ticketForwardBcc = bccOptions.map((option) => option.value).join(",");

    try {
      const response = await fetch(
        "https://ticketsystem.techfluxsolutions.com/agents/agentTicketForward",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ticket_id: ticketId, // Replace with dynamic ticket_id if available
            ticket_forward_cc: ticketForwardCc,
            ticket_forward_bcc: ticketForwardBcc,
            ticket_forward_message: messageContent,
          }),
        }
      );

      if (response.ok) {
        toast.success("Ticket forwarded successfully!");
        // fetchData();
        fetchForwardData();

        // Clear input fields and editor content after successful reply
      setCcOptions([]);
      setBccOptions([]);
      editorRef.current.innerHTML = "";
      } else {
        throw new Error("Failed to forward ticket.");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const [threadData, setThreadData] = useState([]);
  const [error, setError] = useState(null);

  const fetchForwardData = async () => {
    try {
      const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

      if (!token) {
        throw new Error("No authentication token found in session storage.");
      }
      setLoading(true);

      const response = await axios.get(
        `https://ticketsystem.techfluxsolutions.com/agents/getForwardByTicketId/${ticketId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);

      console.log("API Response:", response.data); // Log the response data

      // Extract the forward data and format it
      const forward = response.data.data.forward;
      const formattedReplies = Object.values(forward).map((forwardItem) => ({
        sender: forwardItem.ticket_forward_cc.join(", "), // Join CC addresses to display
        email: forwardItem.ticket_forward_bcc.join(", "), // Join BCC addresses to display
        message: forwardItem.ticket_forward_message,
        replies: [], // Placeholder for nested replies if needed
      }));

      // Set the thread data
      setThreadData(formattedReplies);
    } catch (err) {
      // setError(err.message);
      toast.warning("There is no conversation..");
    } finally {
      setLoading(false);
    }
  };

  //   useEffect(() => {
  //   fetchData();
  // }, [ticketId]);

  return (
    <div className="container reply-container">
      {loading && <Loader />}
      <Mail_fromListViewForward ticketId={ticketId} newReplies={threadData} />
      <div className="reply-header">
        <div className="email-info">
          <label>From:</label>
          <input
            type="text"
            placeholder="helpdesk.techflux@gmail.com"
            className="input-field-reply"
            disabled
          />
        </div>
        <div className="email-info">
          <label>To:</label>
          <input
            type="text"
            value={ticketMail}
            className="input-field-reply"
            disabled
          />
        </div>
        <div className="email-info">
          <label>CC:</label>
          <Select
            isMulti
            value={ccOptions}
            onChange={(selectedOptions) => setCcOptions(selectedOptions)}
            options={CC_OPTIONS}
            placeholder="Add CC"
            className="input-field-reply"
          />
        </div>
        <div className="email-info">
          <label>BCC:</label>
          <Select
            isMulti
            value={bccOptions}
            onChange={(selectedOptions) => setBccOptions(selectedOptions)}
            options={BCC_OPTIONS}
            placeholder="Add BCC"
            className="input-field-reply"
          />
        </div>
      </div>
      <div className="reply-body">
        <div
          ref={editorRef}
          contentEditable
          className="text-area"
          placeholder="Hi Google,"
          onSelect={saveSelection}
          onFocus={() => editorRef.current.focus()}
        ></div>
        <div className="toolbar">
          <BiBold title="Bold" onClick={() => handleCommand("bold")} />
          <BiItalic title="Italic" onClick={() => handleCommand("italic")} />
          <BiUnderline
            title="Underline"
            onClick={() => handleCommand("underline")}
          />
          <span className="divider"></span>
          <BiAlignLeft
            title="Align Left"
            onClick={() => handleCommand("justifyLeft")}
          />
          <BiAlignMiddle
            title="Align Center"
            onClick={() => handleCommand("justifyCenter")}
          />
          <BiAlignRight
            title="Align Right"
            onClick={() => handleCommand("justifyRight")}
          />
          <span className="divider"></span>
          <BiLink
            title="Insert Link"
            onClick={() => {
              const url = prompt("Enter URL:");
              if (url) handleCommand("createLink", url);
            }}
          />
          {/* <BiImage
            title="Insert Image"
            onClick={() => {
              const imageUrl = prompt("Enter image URL:");
              if (imageUrl) handleCommand("insertImage", imageUrl);
            }}
          /> */}
          <span className="divider"></span>
          <BiRedo title="Redo" onClick={() => handleCommand("redo")} />
          <BiUndo title="Undo" onClick={() => handleCommand("undo")} />
        </div>
      </div>
      <div className="reply-footer">
        <button className="send-button-reply" onClick={handleSend}>
          Send
        </button>
        {/* <BsTrash className="trash-icon" title="Delete Draft" /> */}
      </div>
      <ToastContainer /> {/* ToastContainer for error messages */}
    </div>
  );
};

export default Forward;
