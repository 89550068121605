import React, { useEffect, useState } from "react";

import axios from "axios";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Tooltip, OverlayTrigger } from "react-bootstrap";

import AgentCreateModal from "./AgentCreateModal/AgentCreateModal";

import AgentEditModal from "./AgentEditModal/AgentEditModal";

import AgentDeleteModal from "./AgentDeleteModal/AgentDeleteModal";

import { FaEdit, FaTrash, FaEye } from "react-icons/fa";

import Loader from "../../Loader/Loader"; // Assume Loader is a pre-existing component

const Agent = () => {
  const [agents, setAgents] = useState([]);

  const [loading, setLoading] = useState(false);

  const [currentAgent, setCurrentAgent] = useState(null);

  const [showCreateModal, setShowCreateModal] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const fetchAgents = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        "https://ticketsystem.techfluxsolutions.com/agents/getAllAgents_new",

        {
          headers: { Authorization: "TicketSystemSuperAdminToken" },
        }
      );

      if (response?.data?.response) {
        setAgents(response.data.data); // Set agents data from API response

        // toast.success("Agents data retrieved successfully");
      } else {
        toast.error("Failed to retrieve agents data"); // Show error toast if response is not successful
      }
    } catch (error) {
      toast.error("Failed to fetch agents data"); // Show error toast on network failure
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAgents();
  }, []);

  const handleCreate = (newAgent) => {
    setAgents([...agents, { ...newAgent, id: agents.length + 1 }]);

    setShowCreateModal(false);
  };

  // const handleEdit = (updatedAgent) => {

  // setAgents(

  // agents.map((agent) =>

  // agent.agent_id === updatedAgent.agent_id ? updatedAgent : agent

  // )

  // );

  // setShowEditModal(false);

  // };

  const handleEdit = async (agent) => {
    if (!agent || !agent.agent_id) {
      console.log("currentAgent is either null or does not have a agent_id.");

      toast.error("Agent ID is undefined.");

      return;
    }

    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

    // Ensure the token exists

    if (!token) {
      toast.error("Authorization token is missing. Please log in again.");

      return;
    }

    setLoading(true);

    try {
      // Fetch department data

      const response = await axios.get(
        `https://ticketsystem.techfluxsolutions.com/agents/getAgent/${agent.agent_id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const agentData = response.data;

      console.log("Agent Data", agentData.data);

      setCurrentAgent(agentData.data);

      setShowEditModal(true);
    } catch (error) {
      toast.error("Failed to fetch Agent data.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    console.log("Attempting to delete agent with ID:", id); // Log the ID for debugging

    try {
      // Make the DELETE request to the API

      const response = await axios.delete(
        `https://ticketsystem.techfluxsolutions.com/agents/deleteAgent/${id}`,

        {
          headers: { Authorization: "TicketSystemSuperAdminToken" },
        }
      );

      if (response.status === 200) {
        // Check if deletion was successful

        setAgents(agents.filter((agent) => agent.agent_id !== id));

        toast.success("Agent deleted successfully"); // Show success toast

        fetchAgents();
      } else {
        toast.error("Failed to delete agent: Unexpected response from server");
      }
    } catch (error) {
      console.error("Error deleting agent:", error); // Log full error object for debugging

      let errorMessage = "Failed to delete agent: Unknown error occurred";

      if (error.response) {
        if (error.response.data && error.response.data.message) {
          errorMessage = `Failed to delete agent: ${error.response.data.message}`;
        } else if (error.response.status === 404) {
          errorMessage = "Failed to delete agent: Agent not found";
        } else {
          errorMessage = `Failed to delete agent: ${
            error.response.statusText || "Unknown error"
          }`;
        }
      } else if (error.request) {
        errorMessage = "Failed to delete agent: No response from server";
      }

      toast.error(errorMessage); // Show error toast
    } finally {
      setShowDeleteModal(false);
    }
  };

  const renderTooltip = (message) => (
    <Tooltip id="button-tooltip">{message}</Tooltip>
  );

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>Staff</h2>

        <button
          className="btn btn-create-dept"
          onClick={() => setShowCreateModal(true)}
        >
          + New Staff
        </button>
      </div>

      {loading ? (
        <Loader /> // Show loader while data is being fetched
      ) : (
        <table className="table table-bordered text-center ">
          <thead>
            <tr>
              <th>Sr No.</th>

              <th>Name</th>

              <th>Email</th>

              <th>Departments</th>

              <th>Roles</th>

              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            {agents.map((agent, index) => (
              <tr key={agent.agent_id}>
                <td>{index + 1}</td>

                <td>{agent.agent_name}</td>

                <td>{agent.agent_email}</td>

                <td>
                  {agent.departments.length > 0
                    ? agent.departments

                        .map((dept) => dept.department_name)

                        .join(", ")
                    : "No Departments"}
                </td>

                <td>{agent.role_name}</td>

                <td>
                  <div className="icons-wrapper">
                    {/* View Icon */}

                    {/* <OverlayTrigger placement="top" overlay={renderTooltip("View")}>

           <span>

            <FaEye

             className="icon"

             onClick={() => {

              setCurrentAgent(agent);

              setShowViewModal(true);

             }}

            />

           </span>

          </OverlayTrigger> */}

                    {/* Edit Icon */}

                    <OverlayTrigger
                      placement="left"
                      overlay={renderTooltip("Edit")}
                    >
                      <span>
                        <FaEdit
                          className="icon"
                          onClick={() => {
                            setCurrentAgent(agent);

                            handleEdit(agent);

                            setShowEditModal(true);
                          }}
                        />
                      </span>
                    </OverlayTrigger>

                    {/* Delete Icon */}

                    <OverlayTrigger
                      placement="left"
                      overlay={renderTooltip("Delete")}
                    >
                      <span>
                        <FaTrash
                          className="icon"
                          onClick={() => {
                            setCurrentAgent({
                              ...agent,

                              departments: agent.departments || [], // Ensure departments is at least an empty array
                            });

                            setShowDeleteModal(true);
                          }}
                        />
                      </span>
                    </OverlayTrigger>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Modals */}

      <AgentCreateModal
        fetchAgents={fetchAgents}
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
        onCreate={handleCreate}
      />

      <AgentEditModal
        fetchAgents={fetchAgents}
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        agent={currentAgent}

        // fetchAgents={fetchAgents}

        // onEdit={handleEdit}
      />

      <AgentDeleteModal
        fetchAgents={fetchAgents}
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        agent={currentAgent}
        onDelete={handleDelete}
      />

      {/* <ToastContainer /> */}
    </div>
  );
};

export default Agent;
