import React from 'react'
import HeroDashboardSection from './HeroDashboardSection/HeroDashboardSection'
import GraphSection from './GraphSection/GraphSection'
import RecentActivitySeaction from './RecentActivitySection/RecentActivitySeaction'

const Dashboard = () => {
  return (
    <div>
      <HeroDashboardSection/>
      <GraphSection/>
      <RecentActivitySeaction/>
    </div>
  )
}

export default Dashboard