// import React, { useEffect, useState } from "react";
// import { Outlet, useLocation } from "react-router-dom";
// import NavbarComponent from "../NavbarComponent/NavbarComponent";
// import SidebarComponent from "../SidebarComponent/SidebarComponent";

// const LayoutComponent = () => {
//   const location = useLocation();
//   const [pageTitle, setPageTitle] = useState("Support Portal");
//   const [isSidebarOpen, setIsSidebarOpen] = useState(true); // State for sidebar visibility

//   // Update the page title based on the current route
//   useEffect(() => {
//     switch (location.pathname) {
//       case "/dashboard":
//         setPageTitle("Dashboard");
//         break;
//       case "/ticket":
//         setPageTitle("Ticket");
//         break;
//       case "/profile":
//         setPageTitle("Profile");
//         break;
//       default:
//         setPageTitle("Support Portal");
//     }
//   }, [location.pathname]);

//   // Function to toggle the sidebar's visibility
//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };

//   return (
//     <div>
//       {/* Pass toggleSidebar and pageTitle to NavbarComponent */}
//       <NavbarComponent toggleSidebar={toggleSidebar} pageTitle={pageTitle} />
//       <div className="layout">
//         {/* Pass isOpen state to SidebarComponent */}
//         <SidebarComponent isOpen={isSidebarOpen} />
//         <div className="content">
//           <Outlet />  {/* This renders the child route's content (Dashboard, Home, etc.) */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LayoutComponent;







import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import NavbarComponent from "../NavbarComponent/NavbarComponent";
import SidebarComponent from "../SidebarComponent/SidebarComponent";

const LayoutComponent = () => {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("Support Portal");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // State for sidebar visibility

  // Update the page title based on the current route
  useEffect(() => {
    switch (location.pathname) {
      case "/dashboard":
        setPageTitle("Dashboard");
        break;
      case "/ticket":
        setPageTitle("Ticket");
        break;
      case "/profile":
        setPageTitle("Profile");
        break;
        case "/setting":
          setPageTitle("Settings");
          break;
      default:
        setPageTitle("Support Portal");
    }
  }, [location.pathname]);

  // Function to toggle the sidebar's visibility
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      {/* Pass toggleSidebar and isSidebarOpen to NavbarComponent */}
      <NavbarComponent toggleSidebar={toggleSidebar} pageTitle={pageTitle} isOpen={isSidebarOpen} />
      <div className="layout">
        {/* Pass isOpen state to SidebarComponent */}
        <SidebarComponent isOpen={isSidebarOpen} />
        <div className="content">
          <Outlet />  {/* This renders the child route's content (Dashboard, Home, etc.) */}
        </div>
      </div>
    </div>
  );
};

export default LayoutComponent;

