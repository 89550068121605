
// import React, { useState, useEffect } from 'react';
// import { Modal, Button, Form } from 'react-bootstrap';
// import Select from 'react-select';
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import Loader from './../../../Loader/Loader'; // Assuming Loader is correctly imported

// const EditDepartmentModal = ({ show, onHide, department, onEdit }) => {
//     const [name, setName] = useState('');
//     const [selectedAgents, setSelectedAgents] = useState([]);
//     const [businessHours, setBusinessHours] = useState('');
//     const [loading, setLoading] = useState(false);

//     const agentOptions = [
//         { value: 1, label: 'Agent 1' },
//         { value: 2, label: 'Agent 2' },
//         { value: 3, label: 'Agent 3' },
//         { value: 4, label: 'Agent 4' },
//     ];

//     const businessHoursOptions = [
//         { value: 'General Working Hours', label: 'General Working Hours' },
//     ];

//     useEffect(() => {
//         if (department) {
//             setName(prevName => prevName || department.name);
//             setSelectedAgents(prevAgents => prevAgents.length > 0 ? prevAgents : department.agents.map(agent => ({ value: agent, label: `Agent ${agent}` })));
//             setBusinessHours(prevHours => prevHours || department.businessHours);
//         }
//     }, [department]);

//     const handleSubmit = async () => {
//         setLoading(true);
//         const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

//         const agents = selectedAgents.map(agent => agent.value);
//         const updatedDepartment = { ...department, name, agents, businessHours };

//         try {
//             const response = await axios.post(
//                 "http://ticketsystem.techfluxsolutions.com/update_department_new",
//                 updatedDepartment,
//                 {
//                     headers: {
//                         Authorization: `Bearer ${token}`,
//                     },
//                 }
//             );

//             toast.success("Department updated successfully");
//             onEdit(response.data); // Pass updated department back to parent
//             onHide();
//         } catch (error) {
//             toast.error("Failed to update department");
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <Modal show={show} onHide={onHide} centered>
//             <Modal.Header closeButton>
//                 <Modal.Title>Edit Department</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//                 {loading ? (
//                     <Loader />
//                 ) : (
//                     <Form>
//                         <Form.Group>
//                             <Form.Label>Department Name</Form.Label>
//                             <Form.Control 
//                                 type="text"
//                                 className='form-control-department' 
//                                 value={name} 
//                                 onChange={(e) => setName(e.target.value)} 
//                             />
//                         </Form.Group>
//                         {/* <Form.Group>
//                             <Form.Label>Agents</Form.Label>
//                             <Select
//                                 isMulti
//                                 value={selectedAgents}
//                                 onChange={setSelectedAgents}
//                                 options={agentOptions}
//                                 className='form-control-department'
//                                 placeholder="Select Agents"
//                             />
//                         </Form.Group> */}
//                         {/* <Form.Group>
//                             <Form.Label>Business Hours</Form.Label>
//                             <Select
//                                 value={businessHoursOptions.find(option => option.value === businessHours)}
//                                 onChange={(selectedOption) => setBusinessHours(selectedOption.value)}
//                                 options={businessHoursOptions}
//                                 className='form-control-department'
//                                 placeholder="Select Business Hours"
//                             />
//                         </Form.Group> */}
//                     </Form>
//                 )}
//             </Modal.Body>
//             <Modal.Footer>
//                 <Button variant="secondary" onClick={onHide}>
//                     Cancel
//                 </Button>
//                 <Button variant="primary" onClick={handleSubmit} disabled={loading}>
//                     Save Changes
//                 </Button>
//             </Modal.Footer>
//         </Modal>
//     );
// };

// export default EditDepartmentModal;



import React, { useState, useEffect } from 'react';

import { Modal, Button, Form } from 'react-bootstrap';

import axios from 'axios';

import { toast } from 'react-toastify';

import Loader from './../../../Loader/Loader';

const EditDepartmentModal = ({ show, onHide, departmentData ,fetchDepartments}) => {

  const [department, setDepartment] = useState(null);

  const [name, setName] = useState('');

  const [loading, setLoading] = useState(false);

  console.log("modal id",departmentData?.department_id)

  useEffect(() => {

    // Fetch department data when departmentData?.department_id changes

    const fetchDepartmentData = async () => {

      if (!departmentData?.department_id) return;

      setLoading(true);

      const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

      try {

        const response = await axios.get(

          `https://ticketsystem.techfluxsolutions.com/get_department_by_id/${departmentData?.department_id}`,

          {

            headers: {

              Authorization: `Bearer ${token}`,

            },

          }

        );

        const deptData = response.data;

        console.log("",deptData)

        setDepartment(deptData);

        setName(deptData.data.department_name); // Set initial form value

      } catch (error) {

        // toast.error("Failed to fetch department data.");

      } finally {

        setLoading(false);

      }

    };

    fetchDepartmentData();

  }, [departmentData?.department_id]); // Fetches new data when departmentData?.department_id changes

  // Reset state when the modal closes

  useEffect(() => {

    if (!show) {

      setDepartment(null);

      setName('');

    }

  }, [show]);

  const handleSubmit = async () => {

    setLoading(true);

    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

    try {

      const response = await axios.post(

        "https://ticketsystem.techfluxsolutions.com/update_department_new",

        {

          department_id: departmentData?.department_id,

          department_name: name

        },

        {

          headers: {

            Authorization: `Bearer ${token}`,

          },

        }

      );

      console.log("update response",response);

      toast.success("Department updated successfully");

      fetchDepartments()

      onHide();

    } catch (error) {

      toast.error("Failed to update department");

    } finally {

      setLoading(false);

    }

  };

  return (

    <Modal show={show} onHide={onHide} centered>

      <Modal.Header closeButton>

        <Modal.Title>Edit Department</Modal.Title>

      </Modal.Header>

      <Modal.Body>

        {loading ? (

          <Loader />

        ) : (

          <Form>

            <Form.Group>

              <Form.Label>Department Name</Form.Label>

              <Form.Control

                type="text"

                value={name}

                onChange={(e) => setName(e.target.value)}

              />

            </Form.Group>

          </Form>

        )}

      </Modal.Body>

      <Modal.Footer>

        <Button variant="secondary" onClick={onHide}>

          Cancel

        </Button>

        <Button variant="primary" onClick={handleSubmit} disabled={loading}>

          Save Changes

        </Button>

      </Modal.Footer>

    </Modal>

  );

};

export default EditDepartmentModal;

























