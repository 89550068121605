// RecentActivity.js
import React from 'react';
import './RecentActivitySeaction.css'

const RecentActivity = () => {
  // Example recent activities (Replace these with dynamic data later)
  const activities = [
    { id: 1, user: 'Sohel J', action: 'resolved', ticket: 'Server Down', date: '2024-10-24 10:30 AM' },
    { id: 2, user: 'Riya S', action: 'raised', ticket: 'Login Issue', date: '2024-10-23 04:20 PM' },
    { id: 3, user: 'Amit P', action: 'resolved', ticket: 'Payment Failed', date: '2024-10-22 01:15 PM' },
    { id: 4, user: 'Priya K', action: 'raised', ticket: 'Slow Website', date: '2024-10-21 08:45 AM' },
  ];

  return (
    <div className=" col-12 container-recent-activity my-4">
      <h3 className="mb-4">Recent Activities</h3>
      

      <div className="d-flex flex-column align-items-start">
        {activities.map((activity) => (
            <>
          <p key={activity.id} className="mb-2">
            <strong>{activity.user}</strong> {activity.action} the ticket <strong>{activity.ticket}</strong> on{' '}
            {activity.date}
            
          </p>
          <hr style={{width:'100%'}}></hr>
          </>
        ))}
      </div>
    </div>
  );
};

export default RecentActivity;
