import React from 'react';
import './HeroDashboardSection.css';

const data = [
  { id: 1, imgSrc: './assets/images/Dashboard/9.png', value: '2', label: 'Unresolved' },
  { id: 2, imgSrc: './assets/images/Dashboard/2k.png', value: '0', label: 'Overdue' },
  { id: 3, imgSrc: './assets/images/Dashboard/3.png', value: '0', label: 'Due Today' },
  { id: 4, imgSrc: './assets/images/Dashboard/10.png', value: '1', label: 'Open' },
  { id: 5, imgSrc: './assets/images/Dashboard/10.png', value: '1', label: 'Pending' }
];

const HeroDashboardSection = () => {
  return (
    <div className=' containerHeroDashboard p-3 mb-4 mt-5'>
      <div className='row Main_TotalMenu_HeyMaria mt-4'>
        {data.map((item) => (
          <div key={item.id} className='col-12 col-md-2 menu-Item-Graph'>
            <div className='container TotalMenu_HeyMaria'>
              <div className='row'>
                <div className='col-12 col-md-12'>
                  <div className='container mt-2 SubMenu_HeyMaria'>
                    <div className='col-12 col-md-8 label_HeyMaria'>{item.label}</div>
                    <div className='col-12 col-md-4 value_HeyMaria'>{item.value}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeroDashboardSection