import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './../../Credentials/Credentials.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

const Signup = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate

    const validatePassword = (password) => {
        const passwordPattern = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
        return passwordPattern.test(password);
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Check for empty fields
        if (!fullName || !email || !password || !confirmPassword) {
            setError("All fields are required.");
            return;
        }

        // Validate email
        if (!validateEmail(email)) {
            setError("Please enter a valid email address.");
            return;
        }

        // Validate password
        if (!validatePassword(password)) {
            setError("Password must be at least 8 characters long, with at least one uppercase letter, one number, and one special character.");
            return;
        }

        // Check if passwords match
        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        setError("");
        // Handle successful registration (e.g., API call)
        console.log("Registration successful!");
    };

    const handleLoginClick = () => {
        navigate('/'); // Navigate to the login page
    };

    return (
        <div className='container container-credentials'>
            <div className='row'>
                <div className='row-Credentials'>
                    <div className='col-12 col-md-6 inner-container-credentials'>
                        <p className='heading-credentials'>Signup for support portal</p>
                        <form onSubmit={handleSubmit}>
                            {/* Full Name Field */}
                            <div className="mb-3">
                                <label htmlFor="fullName" className="form-label form-label-color">Full Name</label>
                                <input 
                                    type="text" 
                                    className="form-control form-credentials" 
                                    id="fullName" 
                                    value={fullName} 
                                    onChange={(e) => setFullName(e.target.value)} 
                                />
                            </div>

                            {/* Email Field */}
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label form-label-color">Email</label>
                                <input
                                    type="email"
                                    className="form-control form-credentials"
                                    id="exampleInputEmail1"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            {/* Create Password Field */}
                            <div className="mb-3 position-relative">
                                <label htmlFor="createPassword" className="form-label form-label-color">Create Password</label>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    className="form-control form-credentials"
                                    id="createPassword"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <span
                                    className="password-toggle"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                                </span>
                            </div>

                            {/* Confirm Password Field */}
                            <div className="mb-3 position-relative">
                                <label htmlFor="confirmPassword" className="form-label form-label-color">Confirm Password</label>
                                <input
                                    type={showConfirmPassword ? "text" : "password"}
                                    className="form-control form-credentials"
                                    id="confirmPassword"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <span
                                    className="password-toggle"
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                >
                                    {showConfirmPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                                </span>
                            </div>

                            {/* Error Message */}
                            {error && <div className="text-danger mb-3">{error}</div>}

                            {/* Submit Button */}
                            <div>
                                <button type="submit" className="btn btn-credentials">Register</button>
                                <p className='user-login-credentials' onClick={handleLoginClick} style={{ cursor: 'pointer' }}>
                                    Already a user? Login
                                </p> 
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Signup;
