
import axios from "axios";

import React, { useState, useEffect } from "react";

import { Modal, Button, Form } from "react-bootstrap";

import Select from "react-select";

import { toast } from "react-toastify";

const AgentEditModal = ({

 show,

 onHide,

 agent,

 fetchAgents,

}) => {

 const [name, setName] = useState(agent?.agent_name || "");

 const [email, setEmail] = useState(agent?.agent_email || "");

 const [selectedDepartments, setSelectedDepartments] = useState([]);

 const [selectedRoles, setSelectedRoles] = useState([]);

 const [departmentsData, setDepartmentsData] = useState([]);

 const [rolesData, setRolesData] = useState([]);

 const [loading, setLoading] = useState(false);

 useEffect(() => {

  if (agent) {

   // Set agent's name and email

   setName(agent.agent_name);

   setEmail(agent.agent_email);

   // Set selected departments from agent's departments array

   setSelectedDepartments(

    agent.departments?.map((dept) => ({

     value: dept.department_id,

     label: dept.department_name,

    })) || []

   );

   // Set selected role from agent's role_id and role_name

   setSelectedRoles([{ value: agent.role_id, label: agent.role_name }]);

  }

 }, [agent]);

 const handleSubmit = async () => {

  setLoading(true);

  const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

  console.log()

  const role = Array.isArray(selectedRoles) ? selectedRoles[0] : selectedRoles;

  const payload = {

   agent_id: agent?.agent_id,

   agent_name: name || agent?.agent_name,

   agent_email: email || agent?.agent_email,

   selected_department_ids: selectedDepartments.map((dept) => dept.value),

   role_id: selectedRoles?.value || agent?.role_id, // Access value directly with optional chaining

  };

  console.log("selectedDepartments",selectedDepartments)

  console.log("Selected Role:", selectedRoles);

console.log("Selected Role ID:", selectedRoles?.value); // Should log the value directly

  try {

    const response = await axios.post(

      "https://ticketsystem.techfluxsolutions.com/agents/editAgent",

      payload,

      {

        headers: {

          Authorization: `Bearer ${token}`,

        },

      }

    );

    if (response.data.response) {

      toast.success("Agent updated successfully");

      // fetchRoles();

      fetchAgents();

      // fetchAgent,

      onHide();

    } else {

     console.log(response.data.error_msg)

      toast.error(response.data.error_msg ||"Failed to update Agent");

    }

  } catch (error) {

    console.error("API error:", error);

    toast.error("Failed to update Agent");

  } finally {

    setLoading(false);

  }

};

 useEffect(() => {

  const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

  if (!token) {

   toast.error("Authorization token is missing.");

   console.error("Token not found in session storage.");

   return;

  }

  const fetchDepartments = async () => {

   setLoading(true);

   try {

    const response = await fetch("https://ticketsystem.techfluxsolutions.com/get_departments_new", {

     headers: { Authorization: `Bearer ${token}` },

    });

    if (!response.ok) throw new Error(`Error fetching departments: ${response.status}`);

    const data = await response.json();

    if (Array.isArray(data.data)) {

     setDepartmentsData(data.data.map((dept) => ({

      value: dept.department_id,

      label: dept.department_name,

     })));

    } else {

     toast.error("Unexpected response structure for departments.");

     console.error("Unexpected response structure:", data);

    }

   } catch (error) {

    toast.error("Error fetching departments.");

    console.error("Error fetching departments:", error.message);

   } finally {

    setLoading(false);

   }

  };

  const fetchRoles = async () => {

   setLoading(true);

   try {

    const response = await fetch("https://ticketsystem.techfluxsolutions.com/get_roles", {

     headers: { Authorization: `Bearer ${token}` },

    });

    if (!response.ok) throw new Error(`Error fetching roles: ${response.status}`);

    const data = await response.json();

    if (Array.isArray(data.roles || data.data)) {

     const rolesArray = data.roles || data.data;

     setRolesData(rolesArray.map((role) => ({

      value: role.role_id,

      label: role.role_name,

     })));

    } else {

     toast.error("Unexpected response structure for roles.");

     console.error("Unexpected response structure:", data);

    }

   } catch (error) {

    toast.error("Error fetching roles.");

    console.error("Error fetching roles:", error.message);

   } finally {

    setLoading(false);

   }

  };

  if (show) {

   fetchDepartments();

   fetchRoles();

  }

 }, [show]);

 return (

  <Modal show={show} onHide={onHide} centered>

   <Modal.Header closeButton>

    <Modal.Title>Edit Staff</Modal.Title>

   </Modal.Header>

   <Modal.Body>

    <Form>

     <Form.Group controlId="name">

      <Form.Label>Name</Form.Label>

      <Form.Control

       type="text"

       value={name}

       onChange={(e) => setName(e.target.value)}

      />

     </Form.Group>

     <Form.Group controlId="email">

      <Form.Label>Email</Form.Label>

      <Form.Control

       type="email"

       value={email}

       onChange={(e) => setEmail(e.target.value)}

      />

     </Form.Group>

     <Form.Group controlId="departments">

      <Form.Label>Departments</Form.Label>

      <Select

       isMulti

       options={departmentsData}

       value={selectedDepartments}

       onChange={setSelectedDepartments}

       placeholder="Select departments"

       isLoading={loading}

      />

     </Form.Group>

     {/* <Form.Group controlId="role">

      <Form.Label>Role</Form.Label>

      <Select

       options={rolesData}

       value={selectedRoles}

       onChange={(selectedOption) => setSelectedRoles(selectedOption)}

       placeholder="Select role"

       isLoading={loading}

      />

     </Form.Group> */}

     <Form.Group controlId="role">

 <Form.Label>Role</Form.Label>

 <Select

  options={rolesData}

  value={selectedRoles}

  onChange={(selectedOption) => setSelectedRoles(selectedOption || null)} // Set to `null` if no selection

  placeholder="Select role"

  isLoading={loading}

 />

</Form.Group>

    </Form>

   </Modal.Body>

   <Modal.Footer>

    <Button variant="secondary" onClick={onHide}>

     Cancel

    </Button>

    <Button className="btn-create-dept" onClick={handleSubmit}>

     Save Changes

    </Button>

   </Modal.Footer>

  </Modal>

 );

};

export default AgentEditModal;







































































