import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import TicketForm from "../TicketForm/TicketForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ListView.css";
import { FaUser, FaEnvelope } from "react-icons/fa";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MdWatchLater } from "react-icons/md";
import Loader from "../../Loader/Loader";
import axios from "axios";

const DROPDOWN_OPTIONS = {
  priorities: ["Low", "Medium", "High", "Urgent"],
  dueStatuses: ["Open", "Pending", "Resolved", "Closed"],
};

const ListView = () => {
  const navigate = useNavigate();

  // console.log("TiketsList",ticketsData)
  const [defaultTickets, setDefaultTickets] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState([]);
  const [dueStatusDropdownOpen, setDueStatusDropdownOpen] = useState(null);
  const [selectedPriorities, setSelectedPriorities] = useState({});
  const [selectedDueStatuses, setSelectedDueStatuses] = useState([]);
  const [item, setItem] = useState(null);
  const [agents, setAgents] = useState([]);
  const [selectedAgentId, setSelectedAgentId] = useState("");
  const [selectedTicket, setselectedTicket] = useState(null); // Initially set ticket_id as 2

  const dropdownRefs = useRef([]);

  useEffect(() => {
    const storedItem = sessionStorage.getItem("TicketSystemSuperRoleType");
    if (storedItem) {
      setItem(storedItem);
    }
  }, []);

  const dropdownRef = useRef(null);
  const dueStatusDropdownRef = useRef(null);

  const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

  const getPriorityClass = (priority) => {
    const priorityClasses = {
      Low: "priority-low",
      Medium: "priority-medium",
      High: "priority-high",
      Urgent: "priority-urgent",
    };
    return priorityClasses[priority] || "";
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      dropdownRefs.current.forEach((dropdown, index) => {
        if (dropdown && !dropdown.contains(event.target)) {
          setDropdownOpen((prevState) => {
            const updatedDropdownOpen = [...prevState];
            updatedDropdownOpen[index] = false; // Close dropdown for this index
            return updatedDropdownOpen;
          });
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Toggle dropdown open state for the clicked index
  const handlePriorityClick = (index) => {
    setDropdownOpen((prevState) => {
      const updatedDropdownOpen = [...prevState];
      updatedDropdownOpen[index] = !updatedDropdownOpen[index]; // Toggle the dropdown state
      return updatedDropdownOpen;
    });
  };

  // Handle the selection of a priority
  const handleSelectPriority = async (priority, index, ticketId) => {
    // Update the selected priority
    setSelectedPriorities((prev) => {
      const updatedPriorities = [...prev];
      updatedPriorities[index] = priority;
      return updatedPriorities;
    });

    // Update the priority via API and wait for it to complete
    await updatePriority(ticketId, priority);

    // Close the dropdown after selection and API update
    setDropdownOpen((prev) => {
      const updatedDropdownState = [...prev];
      updatedDropdownState[index] = false; // Close dropdown for this index
      return updatedDropdownState;
    });
  };

  // First API call
  const [hasFetchedAgentTickets, setHasFetchedAgentTickets] = useState(false);

  // Function to fetch tickets
  const fetchSuperAdminLists = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://ticketsystem.techfluxsolutions.com/users/list_tickets",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      console.log("Data", data.tickets);

      if (data.response) {
        const agentTickets = data.tickets.map((ticket) => ({
          id: ticket.ticket_id,
          title: ticket.ticket_subject,
          dueStatus: ticket.ticket_status,
          priority: ticket.ticket_priority,
          creator: ticket.ticket_email,
          created: ticket.created_at,
          updated: ticket.updated_at,
          message: ticket.ticket_message,
          agentNameByAPI: ticket.agent_name,
        }));

        // Filter out duplicate tickets by ID before updating state
        setDefaultTickets((prev) => [
          ...prev,
          ...agentTickets.filter(
            (newTicket) => !prev.some((ticket) => ticket.id === newTicket.id)
          ),
        ]);
        setHasFetchedAgentTickets(true);
      } else {
        // toast.error("Failed to retrieve admin tickets");
      }
    } catch (error) {
      toast.error("An error occurred while fetching agent tickets");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Only fetch tickets if item is not 'agent' and has not been fetched yet
    if (item !== "agent" && !hasFetchedAgentTickets) {
      console.log("Fetching agent tickets...");
      fetchSuperAdminLists();
    }

    // Cleanup function to prevent state updates on an unmounted component
    return () => {
      // No need for `isMounted` flag because we handle async state updates carefully in React
    };
  }, [token, item, hasFetchedAgentTickets]);

  useEffect(() => {
    let isMounted = true; // Track if the component is mounted

    const fetchAgentTickets = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          "https://ticketsystem.techfluxsolutions.com/agents/getAllAgentTickets",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        console.log("Data", data.data);

        if (data.response) {
          const agentTickets = data.data.map((ticket) => ({
            id: ticket.ticket_id,
            title: ticket.ticket_subject,
            dueStatus: ticket.ticket_status,
            priority: ticket.ticket_priority,
            creator: ticket.ticket_email,
            created: ticket.created_at,
            updated: ticket.updated_at,
            message: ticket.ticket_message,
            agentNameByAPI: ticket.agent_name,
          }));

          if (isMounted) {
            setDefaultTickets((prev) => [...prev, ...agentTickets]);
            setHasFetchedAgentTickets(true); // Set flag to true after fetching
          }
        } else {
          // toast.error("Failed to retrieve agent tickets");
        }
      } catch (error) {
        toast.error("An error occurred while fetching agent tickets");
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    console.log("ITEM...", hasFetchedAgentTickets);

    if (item === "agent" && hasFetchedAgentTickets) {
      console.log("Fetching agent tickets...");
      fetchAgentTickets();
    } else {
      fetchSuperAdminLists();
    }

    return () => {
      isMounted = false;
    };
  }, [token, item, hasFetchedAgentTickets]);

  // Fetch agents from the API

  const fetchAgents = async () => {
    try {
      const response = await fetch(
        "https://ticketsystem.techfluxsolutions.com/agents/getAllAgents_new",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      console.log("Fetched agents data:", data.data); // Log the data
      if (data && data.data && Array.isArray(data.data)) {
        setAgents(data.data); // Set agents to the fetched array
      } else {
        toast.error("Failed to retrieve agents");
        setAgents([]); // Set to empty array if the response is not valid
      }
    } catch (error) {
      toast.error("An error occurred while fetching agents");
      setAgents([]); // Set to empty array on error
    }
  };
  useEffect(() => {
    fetchAgents();
  }, [token]);

  const handleAgentChange = async (e, ticketssssssss) => {
    const agentName = e.target.value;
    setselectedTicket(ticketssssssss);
    setSelectedAgentId(agentName);

    if (agentName) {
      const agent = agents.find((agent) => agent.agent_name === agentName);

      if (agent) {
        await assignTicketToAgent(agent?.agent_id, ticketssssssss?.id);
      }
    }
  };

  const assignTicketToAgent = async (agentId, ticketId, fetchAgents) => {
    try {
      const response = await fetch(
        "https://ticketsystem.techfluxsolutions.com/assign_ticket",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ticket_id: ticketId,
            agent_id: agentId,
          }),
        }
      );

      const result = await response.json();

      if (response.data.response === true) {
        console.log("Ticket assigned successfully:", result);
        fetchAgents();
      } else {
        console.error("Failed to assign ticket:", result);
      }
    } catch (error) {
      console.error("Error assigning ticket:", error);
    }
  };

  const updateTicketStatus = async (ticketId, status) => {
    try {
      const response = await fetch(
        "https://ticketsystem.techfluxsolutions.com/update_ticket_status",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Ensure `token` is defined in your code
          },
          body: JSON.stringify({
            ticket_id: ticketId,
            ticket_status: status,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("Ticket status updated successfully:", result);
      } else {
        console.error("Failed to update ticket status:", result);
      }
    } catch (error) {
      console.error("Error updating ticket status:", error);
    }
  };

  const handleDueStatusClick = (index) => {
    // Toggle the dropdown open/close
    setDueStatusDropdownOpen(dueStatusDropdownOpen === index ? null : index);
  };

  // Handle status selection and pass ticket ID to the update function
  const handleSelectDueStatus = async (status, index, ticketId) => {
    // Update the selected due status
    setSelectedDueStatuses((prevStatuses) => {
      const updatedStatuses = [...prevStatuses];
      updatedStatuses[index] = status;
      return updatedStatuses;
    });

    // Set the selected ticket
    setselectedTicket(ticketId);

    // Close the dropdown
    setDueStatusDropdownOpen(null);

    // Perform the async operation to update the ticket status
    await updateTicketStatus(ticketId, status);
  };

  useEffect(() => {
    if (defaultTickets.length > 0) {
      setTickets(defaultTickets);
      setSelectedPriorities(defaultTickets.map((ticket) => ticket.priority));
      setSelectedDueStatuses(defaultTickets.map((ticket) => ticket.dueStatus));
    }
  }, [defaultTickets]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(null);
      }
      if (
        dueStatusDropdownRef.current &&
        !dueStatusDropdownRef.current.contains(event.target)
      ) {
        setDueStatusDropdownOpen(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const addNewTicket = (newTicket) => {
    setDefaultTickets((prevTickets) => [newTicket, ...prevTickets]);
  };

  const handleTicketClick = (ticket) => {
    // Navigate to the ticket scenario page with the ticket id
    navigate(`/ticket-scenario/${ticket.id}`);
    // Optionally set additional details in state
    setselectedTicket(ticket);
  };

  const updatePriority = async (ticketId, priority) => {
    try {
      const response = await axios.post(
        "https://ticketsystem.techfluxsolutions.com/users/update_priority_status",
        {
          ticket_id: ticketId,
          ticket_priority: priority,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Assuming token is passed this way
          },
        }
      );
      console.log(response.data);

      // fetchSuperAdminLists();

      setDefaultTickets((prevTickets) =>
        prevTickets.map((ticket) =>
          ticket.id === ticketId ? { ...ticket, priority } : ticket
        )
      );
    } catch (error) {
      console.error("Error updating priority:", error);
    }
  };

  return (
    <div className="container">
      <ToastContainer />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isFormVisible && (
            <TicketForm
              onClose={() => setIsFormVisible(false)}
              addNewTicket={addNewTicket}
            />
          )}

          {tickets.map((ticketssssssss, index) => (
            <div
              key={index}
              className={`ticket-card p-3 mb-3 mt-3 border ${
                selectedDueStatuses[index] === "Closed" ? "disabled-card" : ""
              }`}
            >
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="Status-badge-ListView">
                  <strong className="ticket-title">
                    {selectedDueStatuses[index] === "Closed" ? (
                      <span style={{ color: "gray", cursor: "not-allowed" }}>
                        {ticketssssssss.title} {ticketssssssss.id}
                      </span>
                    ) : (
                      <Link
                        to={`/ticket-scenario/${ticketssssssss.id}/${ticketssssssss.creator}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                        onClick={() => handleTicketClick(ticketssssssss)}
                      >
                        {ticketssssssss.title} {ticketssssssss.id}
                      </Link>
                    )}
                  </strong>
                </div>

                {/* PRIORITY  */}
                <div
                  className={`priority-status ${getPriorityClass(
                    selectedPriorities[index] || "Low"
                  )}`}
                  onClick={() => handlePriorityClick(index)}
                  ref={(el) => (dropdownRefs.current[index] = el)} // Reference the dropdown
                >
                  <span>{selectedPriorities[index] || "Low"}</span>
                  <MdOutlineKeyboardArrowDown />
                  {dropdownOpen[index] && (
                    <div className="dropdown-options">
                      {DROPDOWN_OPTIONS.priorities.map((priority) => (
                        <div
                          key={priority}
                          className={`dropdown-option custom-dropdown-item ${priority.toLowerCase()}`}
                          onClick={() =>
                            handleSelectPriority(
                              priority,
                              index,
                              ticketssssssss.id
                            )
                          } // Pass ticket.id for API update
                        >
                          {priority}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div
                className={`ticket-details ${
                  selectedDueStatuses[index] === "Closed"
                    ? "disabled-content"
                    : ""
                }`}
              >
                <div className="d-flex align-items-center">
                  <FaUser className="mr-1" />
                  <span>{ticketssssssss.creator}</span>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <div
                    style={{
                      flexShrink: 0,
                      width: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <FaEnvelope className="mr-1" />
                  </div>
                  <span
                    className="message-mail-list"
                    dangerouslySetInnerHTML={{ __html: ticketssssssss.message }}
                  ></span>
                </div>


                <div className="d-flex align-items-center mt-2">
                  <MdWatchLater className="mr-1 watch-icon" /> Created:{" "}
                  {ticketssssssss.created}
                  {/* <span>Created: {ticketssssssss.created}</span> */}
                </div>

                {/* <div className="text-muted">
                  First response due: {ticketssssssss.firstResponseDue}
                </div> */}
                {ticketssssssss.due && (
                  <div className="text-muted">Due: {ticketssssssss.due}</div>
                )}
              </div>

              {/* AGENT ASSIGN  */}
              <div className="d-flex justify-content-between align-items-center mt-2">
                {item !== "agent" && (
                  <div>
                    <label>Agent :</label>
                    <select
                      name="agent"
                      className="agent-dropdown"
                      value={selectedAgentId || ticketssssssss?.agentNameByAPI}
                      onChange={(e) => {
                        handleAgentChange(e, ticketssssssss);
                        // setselectedTicket(ticketssssssss);
                      }}
                    >
                      <option value="">Select...</option>
                      {agents.map((agent) => (
                        <option key={agent.agent_id} value={agent.agent_name}>
                          {agent.agent_name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {/* STATUS CHANGE  */}
                <div className="d-flex align-items-center">
                  <div
                    className="due-status-dropdown"
                    onClick={() => {
                      handleDueStatusClick(index);
                      setselectedTicket(ticketssssssss); // Set the selected ticket here
                    }}
                    ref={dueStatusDropdownRef}
                  >
                    <span className="ticket-status">
                      {selectedDueStatuses[index]}
                    </span>
                    <MdOutlineKeyboardArrowDown />
                  </div>
                  {dueStatusDropdownOpen === index && (
                    <div
                      className="custom-dropdown-menu-status"
                      ref={dueStatusDropdownRef}
                    >
                      {DROPDOWN_OPTIONS.dueStatuses.map((status) => (
                        <div
                          key={status}
                          className="dropdown-item-status"
                          onClick={() =>
                            handleSelectDueStatus(
                              status,
                              index,
                              ticketssssssss?.id
                            )
                          } // Pass ticket ID here
                        >
                          {status}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default ListView;
