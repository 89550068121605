// import React from 'react';
// import { FaHome, FaTicketAlt, FaTachometerAlt } from 'react-icons/fa';
// import { MdOutlineSettings } from "react-icons/md";
// import { Link, useLocation } from 'react-router-dom';
// import './SidebarComponent.css';

// const SidebarComponent = ({ isOpen }) => {
//     const location = useLocation();

//     return (
//         <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
//             <ul className="SidebarComponets_TS">
//                 <div className="link-wrapper">
//                     <Link to="/dashboard" className="no-underline">
//                         <li className={`List_Sidebar ${location.pathname === '/dashboard' ? 'active' : ''}`}>
//                             <FaTachometerAlt className="icon" />
//                             {isOpen && <span className='pages_sidebar_Ts'>Dashboard</span>}
//                         </li>
//                     </Link>
//                 </div>
                
//                 <div className="link-wrapper">
//                     <Link to="/ticket" className="no-underline">
//                         <li className={`List_Sidebar ${location.pathname === '/ticket' ? 'active' : ''}`}>
//                             <FaTicketAlt className="icon" />
//                             {isOpen && <span>Tickets</span>}
//                         </li>
//                     </Link>
//                 </div>
                
//                 <div className="link-wrapper">
//                     <Link to="/profile" className="no-underline">
//                         <li className={`List_Sidebar ${location.pathname === '/profile' ? 'active' : ''}`}>
//                             <FaHome className="icon" />
//                             {isOpen && <span>Profile</span>}
//                         </li>
//                     </Link>
//                 </div>

//                 <div className="link-wrapper">
//                     <Link to="/setting" className="no-underline">
//                         <li className={`List_Sidebar ${location.pathname === '/setting' ? 'active' : ''}`}>
//                             <MdOutlineSettings className="icon" />
//                             {isOpen && <span>Settings</span>}
//                         </li>
//                     </Link>
//                 </div>
//             </ul>
//         </div>
//     );
// };

// export default SidebarComponent;



import React, { useEffect, useState } from 'react';
import { FaHome, FaTicketAlt, FaTachometerAlt } from 'react-icons/fa';
import { MdOutlineSettings } from "react-icons/md";
import { Link, useLocation } from 'react-router-dom';
import './SidebarComponent.css';

const SidebarComponent = ({ isOpen }) => {
    const location = useLocation();
    const [item, setItem] = useState(null);

    useEffect(() => {
        // Retrieve the item from sessionStorage
        const storedItem = sessionStorage.getItem('TicketSystemSuperRoleType');
        if (storedItem) {
            setItem(storedItem); // Update state if the item exists
        }
    }, []);

    return (
        <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
            <ul className="SidebarComponets_TS">
                <div className="link-wrapper">
                    <Link to="/dashboard" className="no-underline">
                        <li className={`List_Sidebar ${location.pathname === '/dashboard' ? 'active' : ''}`}>
                            <FaTachometerAlt className="icon" />
                            {isOpen && <span className='pages_sidebar_Ts'>Dashboard</span>}
                        </li>
                    </Link>
                </div>
                
                <div className="link-wrapper">
                    <Link to="/ticket" className="no-underline">
                        <li className={`List_Sidebar ${location.pathname === '/ticket' ? 'active' : ''}`}>
                            <FaTicketAlt className="icon" />
                            {isOpen && <span>Tickets</span>}
                        </li>
                    </Link>
                </div>
                
                {/* <div className="link-wrapper">
                    <Link to="/profile" className="no-underline">
                        <li className={`List_Sidebar ${location.pathname === '/profile' ? 'active' : ''}`}>
                            <FaHome className="icon" />
                            {isOpen && <span>Profile</span>}
                        </li>
                    </Link>
                </div> */}

                {/* Render Settings only if role type is not "agent" */}
                {item !== 'agent' && (
                    <div className="link-wrapper">
                        <Link to="/setting" className="no-underline">
                            <li className={`List_Sidebar ${location.pathname === '/setting' ? 'active' : ''}`}>
                                <MdOutlineSettings className="icon" />
                                {isOpen && <span>Settings</span>}
                            </li>
                        </Link>
                    </div>
                )}
            </ul>
        </div>
    );
};

export default SidebarComponent;