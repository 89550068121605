// EditProfile.js
import React, { useEffect, useState } from 'react';
import './EditProfile.css'; // Import external CSS

const EditProfile = ({ userData, onClose, onUpdate }) => {
  const [isVisible, setIsVisible] = useState(false); // State to control the slide-in effect

  useEffect(() => {
    // Trigger the slide-in effect when component mounts
    setIsVisible(true);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(userData); // Pass updated data to parent
    handleClose(); // Close the panel
  };

  const handleClose = () => {
    // Trigger slide-out animation
    setIsVisible(false);
    setTimeout(() => {
      onClose(); // Close after animation completes
    }, 300); // Match with CSS animation duration
  };

  return (
    <div className={`edit-profile-container ${isVisible ? 'slide-in' : 'slide-out'}`}>
      <div className="edit-profile-content">
        <h3>Edit Profile</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              name="name"
              value={userData.name}
              onChange={(e) => onUpdate({ ...userData, name: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={userData.email}
              onChange={(e) => onUpdate({ ...userData, email: e.target.value })}
            />
          </div>
          <div className="button-group ">
            <button type="submit" className="btn-update">
              Update Changes
            </button>
            <button type="button" onClick={handleClose} className="btn-cancel">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
