// import React, { useState } from 'react';
// import './TicketForm.css';

// const TicketForm = ({ onClose, addNewTicket }) => {
//   const [formData, setFormData] = useState({
//     contact: "",
//     subject: "",
//     type: "",
//     source: "",
//     status: "New",
//     priority: "Low",
//     group: "",
//     agent: "",
//     description: "",
//   });

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const newTicket = {
//       ...formData,
//       id: `#${Math.floor(Math.random() * 1000) + 1}`, // Ensure unique IDs
//       title: formData.subject,
//       creator: formData.contact,
//       created: new Date().toLocaleString(), // Capture the current time
//       firstResponseDue: "First response due in 5 hours", // This could be dynamic
//       priority: formData.priority,
//       agent: formData.agent || "--", // Handle if agent is not selected
//       dueStatus: formData.status, // Directly set the due status from the selected status
//     };

//     addNewTicket(newTicket);
//     alert("Ticket submitted!");

//     // Reset form state after submission
//     setFormData({
//       contact: "",
//       subject: "",
//       type: "",
//       source: "",
//       status: "New",
//       priority: "Low",
//       group: "",
//       agent: "",
//       description: "",
//     });

//     onClose();
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   return (
//     <form onSubmit={handleSubmit} className="ticket-form">
//       <h2>Create New Ticket</h2>
//       <div className="form-group-ticket-form">
//         <label>Contact<span className="required"> * </span>:</label>
//         <input type="text" name="contact" value={formData.contact} onChange={handleChange} required />
//       </div>
//       <div className="form-group-ticket-form">
//         <label>Subject<span className="required"> * </span>:</label>
//         <input type="text" name="subject" value={formData.subject} onChange={handleChange} required />
//       </div>
      
//       {/* Commented out the Type field */}
//       {/*
//       <div className="form-group-ticket-form">
//         <label>Type<span className="required"> * </span>:</label>
//         <select name="type" value={formData.type} onChange={handleChange} required>
//           <option value="">Select...</option>
//           <option value="question">Question</option>
//           <option value="incident">Incident</option>
//           <option value="problem">Problem</option>
//           <option value="feature_request">Feature Request</option>
//           <option value="refund">Refund</option>
//         </select>
//       </div>
//       */}

//       {/* Commented out the Source field */}
//       {/*
//       <div className="form-group-ticket-form">
//         <label>Source<span className="required"> * </span>:</label>
//         <select name="source" value={formData.source} onChange={handleChange} required>
//           <option value="">Select...</option>
//           <option value="phone_no">Phone No.</option>
//         </select>
//       </div>
//       */}



//       {/*<div className="form-group-ticket-form">
//         <label>Status<span className="required"> * </span>:</label>
//         <select name="status" value={formData.status} onChange={handleChange} required>
//           <option value="">Select...</option>
//           <option value="Open">Open</option>
//           <option value="Pending">Pending</option>
//           <option value="Resolved">Resolved</option>
//           <option value="Closed">Closed</option>
//         </select>
//       </div>*/}


//       <div className="form-group-ticket-form">
//         <label>Priority<span className="required"> * </span>:</label>
//         <select name="priority" value={formData.priority} onChange={handleChange} required>
//           <option value="">Select...</option>
//           <option value="Low">Low</option>
//           <option value="Medium">Medium</option>
//           <option value="High">High</option>
//           <option value="Urgent">Urgent</option>
//         </select>
//       </div>
      
//       {/* Commented out the Group field */}
//       {/*
//       <div className="form-group-ticket-form">
//         <label>Group :</label>
//         <select name="group" value={formData.group} onChange={handleChange}>
//           <option value="">Select...</option>
//           <option value="Technical_Support">Technical Support</option>
//         </select>
//       </div>
//       */}

//       {/* <div className="form-group-ticket-form">
//         <label>Agent :</label>
//         <select name="agent" value={formData.agent} onChange={handleChange}>
//           <option value="">Select...</option>
//           <option value="Jyoti">Jyoti</option>
//           <option value="Sohel_J">Sohel J</option>
//           <option value="Khushi">Khushi</option>
//           <option value="Aboli">Aboli</option>
//         </select>
//       </div> */}

//       {/* Commented out the Description field */}
//       {/*
//       <div className="form-group-ticket-form">
//         <label>Description :</label>
//         <textarea name="description" value={formData.description} onChange={handleChange} rows="4"></textarea>
//       </div>
//       */}

//       <div className="buttons-ticket-form">
//         <button type="submit" className="submit-button-ticket-form">Create Ticket</button>
//         <button type="button" className="cancel-button-ticket-form" onClick={onClose}>Cancel</button>
//       </div>
//     </form>
//   );
// };

// export default TicketForm;


import React, { useEffect, useState } from 'react';
import './TicketForm.css';
import { toast } from 'react-toastify';
import Loader from '../../Loader/Loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TicketForm = ({ onClose, addNewTicket }) => {
  const [loading,setLoading]=useState(false);
  const [formData, setFormData] = useState({
    contact: "",
    email: "", // Changed from emailId to email
    subject: "",
    type: "",
    source: "",
    status: "New",
    priority: "Low",
    group: "",
    agent: "",
    description: "",
  });

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   // Construct the ticket data object with required fields
  //   const newTicket = {
  //     contact: formData.contact,
  //     ticket_email: formData.email, // This will now match the API’s expected 'email' field
  //     ticket_subject: formData.subject,
  //     ticket_priority: formData.priority,

  //   };

  //   const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

  //   try {
  //     setLoading(true)
  //     const response = await fetch("https://ticketsystem.techfluxsolutions.com/users/add_ticket", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Authorization": `Bearer ${token}`
  //       },
  //       body: JSON.stringify(newTicket),
  //     });

  //     if (response.ok) {
  //       const result = await response.json();
  //       addNewTicket(result);
  //       toast.success("Ticket Added Successfully!");

  //       setFormData({
  //         contact: "",
  //         email: "",
  //         subject: "",
  //         type: "",
  //         source: "",
  //         status: "New",
  //         priority: "Low",
  //         group: "",
  //         agent: "",
  //         description: "",
  //       });
        
  //       onClose();
  //     } else {
  //       const errorData = await response.json();
  //       toast.error(errorData.error_msg || "Failed to submit ticket. Please try again.");
  //     }
  //   } catch (error) {
  //     setLoading(false)
  //     console.error("Error submitting ticket:", error);
  //     toast.error("An error occurred. Please try again later.");
  //   }
  //   finally{
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
    const newTicket = {
      contact: formData.contact,
      ticket_email: formData.email,
      ticket_subject: formData.subject,
      ticket_priority: formData.priority,
    };
  
    try {
      setLoading(true);
      const response = await fetch("https://ticketsystem.techfluxsolutions.com/users/add_ticket", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(newTicket),
      });
  
      if (response.ok) {
        const result = await response.json();
        toast.success("Ticket Added Successfully!");
        addNewTicket(result);
        
        setFormData({
          contact: "",
          email: "",
          subject: "",
          type: "",
          source: "",
          status: "New",
          priority: "Low",
          group: "",
          agent: "",
          description: "",
        });
        onClose();
      } else {
        const errorData = await response.json();
        toast.error(errorData.error_msg || "Failed to submit ticket. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting ticket:", error);
      toast.error("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // useEffect(() => {
  //   toast.success("Toast test!");
  // }, []);
  


  if(loading){
    return <Loader/>
  }
  return (
    <>
        <form onSubmit={handleSubmit} className="ticket-form">
      <h2>Create New Ticket</h2>
      {/* <div className="form-group-ticket-form">
        <label>Contact<span className="required"> * </span>:</label>
        <input type="text" name="contact" value={formData.contact} onChange={handleChange} required />
      </div> */}
      
      <div className="form-group-ticket-form">
        <label>Email<span className="required"> * </span>:</label>
        <input type="email" name="email" value={formData.email} onChange={handleChange} required />
      </div>
      
      <div className="form-group-ticket-form">
        <label>Subject<span className="required"> * </span>:</label>
        <input type="text" name="subject" value={formData.subject} onChange={handleChange} required />
      </div>
      
      <div className="form-group-ticket-form">
        <label>Priority<span className="required"> * </span>:</label>
        <select name="priority" value={formData.priority} onChange={handleChange} required>
          <option value="">Select...</option>
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
          <option value="Urgent">Urgent</option>
        </select>
      </div>

      <div className="buttons-ticket-form">
        <button type="submit" className="submit-button-ticket-form">Create Ticket</button>
        <button type="button" className="cancel-button-ticket-form" onClick={onClose}>Cancel</button>
      </div>
        </form>
        <ToastContainer position="top-right" autoClose={5000} />
    </>
    
    
  );
};

export default TicketForm;

