import React, { useState, useEffect } from 'react';
import Department from './Department/Department';
import Roles from './Roles/Roles';
import Agents from './Agents/Agents';
import GmailConnectivity from './GmailConnectivity/GmailConnectivity';
import './Settings.css'; // Optional: Add your own styles

const Settings = () => {
  const [activeTab, setActiveTab] = useState('department');

  // Load the tab from localStorage on component mount
  useEffect(() => {
    const savedTab = localStorage.getItem('activeTab');
    if (savedTab) {
      setActiveTab(savedTab);
    }
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    localStorage.setItem('activeTab', tab); // Save tab to localStorage
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'department':
        return <Department />;
      case 'roles':
        return <Roles />;
      case 'agents':
        return <Agents />;
      case 'gmail':
        return <GmailConnectivity />;
      default:
        return <Department />;
    }
  };

  return (
    <div className="settings-container">
      <div className="sidebar-settings">
        <form>
          <label>
            <input
              type="radio"
              name="tab"
              className="radio-settings"
              value="department"
              checked={activeTab === 'department'}
              onChange={() => handleTabChange('department')}
            />
            Department
          </label>

          <label>
            <input
              type="radio"
              name="tab"
              className="radio-settings"
              value="roles"
              checked={activeTab === 'roles'}
              onChange={() => handleTabChange('roles')}
            />
            Roles
          </label>

          <label>
            <input
              type="radio"
              name="tab"
              className="radio-settings"
              value="agents"
              checked={activeTab === 'agents'}
              onChange={() => handleTabChange('agents')}
            />
            Staff
          </label>

          <label>
            <input
              type="radio"
              name="tab"
              className="radio-settings"
              value="gmail"
              checked={activeTab === 'gmail'}
              onChange={() => handleTabChange('gmail')}
            />
            Gmail Connectivity
          </label>
        </form>
      </div>

      <div className="content-settings">
        {renderContent()}
      </div>
    </div>
  );
};

export default Settings;
