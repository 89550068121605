import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify"; // Import toast and ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import toast CSS
import "./AddNote.css";
import Loader from "../../../Loader/Loader"; // Import your custom loader
import axios from "axios";
import Mail_fromListViewNote from "../Mail_fromListView/Mail_fromListViewNote";
const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

const AddNote = ({ onAddNote, ticketId }) => {
  const [noteType, setNoteType] = useState("Private"); // default as private
  const [noteContent, setNoteContent] = useState("");
  const [notifyTo, setNotifyTo] = useState("");
  const [isLoading, setIsLoading] = useState(false); // to handle loading state
  const [error, setError] = useState(""); // to handle error state
  const [ticketNotes, setTicketNotes] = useState([]); // to store the fetched notes

  // Function to fetch ticket notes from the GET API
  const fetchTicketNotes = async () => {
    if (!ticketId) {
      setError("Ticket ID is missing.");
      toast.error("Ticket ID is missing.");
      return;
    }

    setIsLoading(true); // Show loader while fetching notes
    setError(""); // Reset any previous errors

    try {
      const url =
        noteType === "Private"
          ? `https://ticketsystem.techfluxsolutions.com/agents/getAllTicketNote/${ticketId}`
          : `https://ticketsystem.techfluxsolutions.com/agents/getAllPublicTicketNote/${ticketId}`;

      const response = await fetch(url, {
        method: "GET",
        headers:
          noteType === "Private"
            ? {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              }
            : {
                "Content-Type": "application/json",
              },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch ticket notes.");
      }

      const data = await response.json();
      console.log(`Fetched ${noteType} ticket notes:`, data);

      if (data.response) {
        setTicketNotes(data.data || []);
      } else {
        setTicketNotes([]);
        const errorMsg = data.error_msg || "No notes found for this ticket.";
        setError(errorMsg);
        toast.warning(
          "There Is No note available for this ticket. Please add Note."
        );
      }
    } catch (error) {
      const errorMsg = "Error fetching ticket notes. Please try again later.";
      setError(errorMsg);
      toast.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  // Use effect to trigger the fetch when noteType is set to Private
  useEffect(() => {
    fetchTicketNotes(); // Fetch notes whenever noteType or ticketId changes
  }, [noteType, ticketId]);
  const handleAddNote = async () => {
    if (noteContent.trim() === "") {
      alert("Note content cannot be empty.");
      return;
    }

    setIsLoading(true);
    setError("");

    const requestPayload = {
      ticket_id: ticketId,
      ticket_note_cc: notifyTo,
      ticket_note: noteContent,
      is_public: noteType === "Public" ? 1 : 0,
    };

    try {
      const response = await fetch(
        "https://ticketsystem.techfluxsolutions.com/agents/ticketNoteInsert_new",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestPayload),
        }
      );

      const data = await response.json();
      console.log("Response status:", response.status);
      console.log("Response data:", data);

      if (response.ok && data?.response === true) {
        // Ensure onAddNote is passed correctly and is a function
        if (typeof onAddNote === "function") {
          onAddNote({
            noteType,
            noteContent,
            notifyTo,
          });
        } else {
          console.error("onAddNote is not a function");
        }

        setNoteContent("");
        setNotifyTo("");
        setNoteType("Private");

        toast.success("Note added successfully!");
        // fetchData();
        fetchNoteData();
      } else {
        const errorMsg = data?.success_msg || "Failed to add note.";
        setError(errorMsg);
        toast.error(errorMsg);
      }
    } catch (error) {
      console.error("Error caught in catch block:", error);
      const errorMsg = "Error adding note. Please try again later.";
      setError(errorMsg);
      toast.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  const [threadData, setThreadData] = useState([]);

  const fetchNoteData = async () => {
    try {
      const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

      if (!token) {
        throw new Error("No authentication token found in session storage.");
      }
      setIsLoading(true);

      const response = await axios.get(
        `https://ticketsystem.techfluxsolutions.com/agents/getAllTicketNote/${ticketId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false);

      console.log("API Response:", response.data); // Log the response data

      // Extract and format the note data
      const notes = response.data.data;
      const formattedReplies = notes.map((note) => ({
        sender: note.agent_email, // Email of the agent
        cc: note.ticket_note_cc, // CC email address
        message: note.ticket_note, // Note message content
        createdAt: note.created_at, // Creation date if needed
        replies: [], // Placeholder for nested replies if needed
      }));

      // Set the thread data
      setThreadData(formattedReplies);
    } catch (err) {
      toast.warning("There is no conversation..");
    } finally {
      setIsLoading(false);
    }
  };

  //   useEffect(() => {
  //   fetchData();
  // }, [ticketId]);

  return (
    <div className="container note-container">
      <Mail_fromListViewNote ticketId={ticketId} newReplies={threadData} />
      <div className="note-header">
        <select
          value={noteType}
          onChange={(e) => setNoteType(e.target.value)}
          className="note-type-dropdown"
        >
          <option value="Private">Private</option>
          <option value="Public">Public</option>
        </select>
      </div>
      <div className="note-body">
        {noteType === "Private" && (
          <input
            type="text"
            className="note-input"
            placeholder="Notify to: Add a note, @mention, or @someone@email.com"
            value={notifyTo}
            onChange={(e) => setNotifyTo(e.target.value)}
          />
        )}
        <textarea
          className="note-textarea"
          placeholder="Add your note here..."
          value={noteContent}
          onChange={(e) => setNoteContent(e.target.value)}
        />
      </div>

      <div className="note-footer">
        {/* <button className="cancel-button" onClick={() => setNoteContent("")}>
          Cancel
        </button> */}
        <button
          className="add-note-button"
          onClick={handleAddNote}
          disabled={isLoading} // Disable button while loading
        >
          {isLoading ? "Adding..." : "Add note"}
        </button>
      </div>

      {/* Show your custom loader if isLoading is true */}
      {isLoading && <Loader />}
      {/* Toast container to show notifications */}
      <ToastContainer />
    </div>
  );
};

export default AddNote;
