import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Mail_fromListView.css";
import Loader from "../../../Loader/Loader";
import { toast } from "react-toastify";

const Mail_fromListViewNote = ({ ticketId, newReplies }) => {
  const [threadData, setThreadData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchNoteData = async () => {
    try {
      const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

      if (!token) {
        throw new Error("No authentication token found in session storage.");
      }
      setLoading(true);

      const response = await axios.get(
        `https://ticketsystem.techfluxsolutions.com/agents/getAllTicketNote/${ticketId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);

      console.log("API Response:", response.data); // Log the response data

      // Extract and format the note data
      const notes = response.data.data;
      const formattedReplies = notes.map((note) => ({
        sender: note.agent_email, // Email of the agent
        cc: note.ticket_note_cc, // CC email address
        message: note.ticket_note, // Note message content
        createdAt: note.created_at, // Creation date if needed
        replies: [], // Placeholder for nested replies if needed
      }));

      // Set the thread data
      setThreadData(formattedReplies);
    } catch (err) {
      toast.warning("There is no conversation..");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNoteData();
  }, [ticketId]);

  useEffect(() => {
    setThreadData(newReplies);
  }, [newReplies]);

  const renderThread = (thread) => {
    if (!Array.isArray(thread)) {
      return <div>Invalid thread data</div>;
    }

    return thread.map((email, index) => (
      <div key={index} className="mail-thread-item mb-5">
        <div className="mail-thread-header">
          <span className="mail-thread-sender">{email.sender}</span>
          <span className="mail-thread-address">&lt;{email.cc}&gt;</span>
          {/* Optionally display the created date if needed */}
          {/* <span className="mail-thread-date">{email.createdAt}</span> */}
        </div>
        <div
          className="mail-thread-message"
          dangerouslySetInnerHTML={{ __html: email.message }}
        ></div>
        {email.replies.length > 0 && (
          <div className="mail-thread-replies">
            {renderThread(email.replies)}
          </div>
        )}
      </div>
    ));
  };

  if (loading) return <Loader />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="mail-thread-container">{renderThread(threadData)}</div>
  );
};

export default Mail_fromListViewNote;
