import React, { useState } from "react";
import "./KanbanView.css";
import {
  FaUser,
  FaCalendarAlt,
  FaEnvelope,
  FaChevronDown,
  FaPen,
} from "react-icons/fa";

const PRIORITIES = [
  { label: "Low", value: "Low", color: "green" },
  { label: "Medium", value: "Medium", color: "blue" },
  { label: "High", value: "High", color: "orange" },
  { label: "Urgent", value: "Urgent", color: "red" },
];

const AGENTS = [
  { label: "Anurag", value: "Anurag" },
  { label: "GlitchDev", value: "GlitchDev" },
  { label: "DevTeam", value: "DevTeam" },
];

const KanbanView = () => {
  const [cards, setCards] = useState([
    {
      id: "0",
      title: "Test Story",
      status: "Open",
      priority: "High",
      agent: "",
      created_at: "2024-11-03",
      user_email: "test@gmail.com",
      color: "#7bed9f",
      message:
        "This is test content of a story.This is test content of a story.This is test content of a story.",
    },
    {
      id: "1",
      title: "Test Bug",
      status: "Pending",
      priority: "Medium",
      agent: "",
      created_at: "2024-11-05",
      user_email: "glitchdev@bug.com",
      color: "#ff6b81",
      message: "This is test content of a bug.",
    },
    {
      id: "2",
      title: "Test Task",
      status: "Resolved",
      priority: "Urgent",
      agent: "",
      created_at: "2024-11-07",
      user_email: "devteam@task.com",
      color: "#70a1ff",
      message: "This is test content of a task.",
    },
    {
      id: "3",
      title: "Test Task",
      status: "Closed",
      priority: "Urgent",
      agent: "",
      created_at: "2024-11-07",
      user_email: "devteam@task.com",
      color: "#70a1ff",
      message: "This is test content of a task.",
    },
  ]);

  const handleDragStart = (ev) => {
    ev.dataTransfer.setData("text/plain", ev.target.id);

    const cardWrappers = document.getElementsByClassName(
      "kanban-cards-wrapper"
    );
    Array.from(cardWrappers).forEach((c) => {
      c.classList.add("card-placeable");
    });
  };

  const handleDragEnd = () => {
    const cardWrappers = document.getElementsByClassName(
      "kanban-cards-wrapper"
    );
    Array.from(cardWrappers).forEach((c) => {
      c.classList.remove("card-placeable");
    });
  };

  const handleDragOver = (ev) => {
    ev.preventDefault();
    ev.dataTransfer.dropEffect = "move";
  };

  const handleDrop = (ev, status) => {
    ev.preventDefault();
    const cardId = ev.dataTransfer.getData("text/plain");
    const updatedCards = cards.map((card) =>
      card.id === cardId ? { ...card, status } : card
    );
    setCards(updatedCards);
  };

  const columns = ["Open", "Pending", "Resolved", "Closed"];
  return (
    <div className="kanban-wrapper">
      {columns.map((column) => (
        <div className="kanban-container" key={column}>
          <h1 className="kanban-column-title">{column}</h1>
          <div
            className="kanban-cards-wrapper"
            onDragOver={handleDragOver}
            onDrop={(ev) => handleDrop(ev, column)}
          >
            {cards
              .filter((card) => card.status === column)
              .map((card) => (
                <div
                  key={card.id}
                  draggable="true"
                  id={card.id}
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  className="kanban-card"
                >
                  <div className="kanban-type">
                    <span
                      className="kanban-title"
                      style={{
                        background: card.color,
                        padding: "7px 15px",
                        borderRadius: "20px",
                      }}
                    >
                      {card.title}
                    </span>
                  </div>
                  <div className="kanban-content-section">
                    <div
                      className="kanban-email-row"
                      style={{ marginBottom: "5px", alignItems: "start" }}
                    >
                      <div
                        style={{
                          flexShrink: 0,
                          width: "30px",
                          display: "flex",
                          justifyContent: "center",
                          paddingTop:"5px",
                        }}
                      >
                        <FaEnvelope className="kanban-icon" />
                      </div>
                      <div style={{ flex: 1 }}>
                        <span>{card.message}</span>
                      </div>
                    </div>
                    <div className="kanban-email-row">
                      <FaUser className="kanban-icon" />
                      {card.user_email}
                    </div>
                    <div className="kanban-footer">
                      <div className="kanban-footer-item">
                        <FaCalendarAlt className="kanban-icon" />
                        {card.created_at}
                      </div>
                      <div className="kanban-footer-agent-priority">
                        <div className="kanban-agent-dropdown">
                          <select
                            className="kanban-agent-select"
                            style={{
                              boxShadow: "none",
                              border: "none",
                              background: "transparent",
                            }}
                            value={card.agent || ""}
                            onChange={(e) =>
                              setCards(
                                cards.map((c) =>
                                  c.id === card.id
                                    ? { ...c, agent: e.target.value }
                                    : c
                                )
                              )
                            }
                          >
                            <option value="" disabled>
                              Select Agent
                            </option>
                            {AGENTS.map((agent) => (
                              <option key={agent.value} value={agent.value}>
                                {agent.label}
                              </option>
                            ))}
                          </select>
                          {/* <FaChevronDown className="dropdown-icon-kanban" /> */}
                        </div>
                        <div className="kanban-priority-dropdown">
                          <select
                            className={`kanban-priority-select ${card.priority.toLowerCase()}`}
                            value={card.priority}
                            onChange={(e) =>
                              setCards(
                                cards.map((c) =>
                                  c.id === card.id
                                    ? { ...c, priority: e.target.value }
                                    : c
                                )
                              )
                            }
                          >
                            {PRIORITIES.map((priority) => (
                              <option
                                key={priority.value}
                                value={priority.value}
                                style={{ color: priority.color }}
                              >
                                {priority.label}
                              </option>
                            ))}
                          </select>
                          <FaChevronDown className="dropdown-icon-kanban" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default KanbanView;