// Profile.js
import React, { useState } from 'react';
import './Profile.css'; // Import external CSS
import EditProfile from './EditProfile/EditProfile'; // Import EditProfile component

const USER_DATA = {
  name: 'sohel j',
  email: 'sohel@techflux.in',
  avatar: 'S', // Fallback avatar letter
};

const Profile = () => {
  const [userData, setUserData] = useState(USER_DATA); // User data state
  const [isEditing, setIsEditing] = useState(false); // State to control edit panel visibility

  // Handle opening the edit panel
  const handleEdit = () => setIsEditing(true);

  // Handle closing the edit panel
  const handleClose = () => setIsEditing(false);

  // Handle updating user data
  const handleUpdate = (newData) => setUserData(newData);

  return (
    <div className="container-fluid p-4 profile-container">
      <div className="row">
        <div className="col-12 col-sm-6 col-md-4 col-lg-4 user-card-container">
          <div className="user-card p-4">
            <div className="user-avatar">{userData.avatar}</div>
            <div className="card-body-profile text-center">
              <h5 className="user-name">{userData.name}</h5>
              <p className="user-email">
                <i className="bi bi-envelope"></i> {userData.email}
              </p>
              <button className="btn-edit" onClick={handleEdit}>
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Render EditProfile component when isEditing is true */}
      {isEditing && (
        <EditProfile
          userData={userData}
          onClose={handleClose}
          onUpdate={handleUpdate}
        />
      )}
    </div>
  );
};

export default Profile;
