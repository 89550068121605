// import React, { useState, useEffect } from "react";
// import { Modal, Button, Form } from "react-bootstrap";
// import Select from "react-select";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Loader from "../../../Loader/Loader";

// const AgentCreateModal = ({
//   show,
//   onHide,
//   onCreate,
//   fetchAgents,
//   roles = ["Admin", "Support", "Manager"], // Sample roles if rolesData not fetched
//   departments = ["Sales", "Tech", "HR", "Customer Service"], // Sample departments
// }) => {
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [selectedDepartments, setSelectedDepartments] = useState([]);
//   const [departmentsData, setDepartmentsData] = useState([]); // Initialized as empty array
//   const [rolesData, setRolesData] = useState([]);
//   const [selectedRole, setSelectedRole] = useState(null);
//   const [loading, setLoading] = useState(false);

//   const resetForm = () => {
//     setName("");
//     setEmail("");
//     setSelectedDepartments([]);
//     setSelectedRole(null);
//   };

//   useEffect(() => {
//     const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

//     if (!token) {
//       toast.error("Authorization token is missing.");
//       console.error("Token not found in session storage.");
//       return;
//     }

//     const fetchDepartments = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch(
//           "https://ticketsystem.techfluxsolutions.com/get_departments_new",
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         if (!response.ok) {
//           const errorText = await response.text();
//           console.error("Fetch error:", response.status, errorText);
//           throw new Error(`Error fetching departments: ${response.status}`);
//         }

//         const data = await response.json();

//         if (Array.isArray(data.data)) {
//           setDepartmentsData(
//             data.data.map((dept) => ({
//               value: dept.department_id,
//               label: dept.department_name,
//             }))
//           );
//         } else {
//           toast.error("Unexpected response structure for departments.");
//           console.error("Unexpected response structure:", data);
//         }
//       } catch (error) {
//         toast.error("Error fetching departments.");
//         console.error("Error fetching departments:", error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     const fetchRoles = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch(
//           "https://ticketsystem.techfluxsolutions.com/get_roles",
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         if (!response.ok) {
//           const errorText = await response.text();
//           console.error("Fetch error:", response.status, errorText);
//           throw new Error(`Error fetching roles: ${response.status} - ${errorText}`);
//         }

//         const data = await response.json();
//         console.log("Fetched roles:", data.roles);

//         // Check if 'data' is an array itself
//         if (Array.isArray(data.roles)) {
//           setRolesData(
//             data.roles.map((role) => ({
//               value: role.role_id,
//               label: role.role_name,
//             }))
//           );
//         } else if (Array.isArray(data.data)) {
//           setRolesData(
//             data.data.map((role) => ({
//               value: role.role_id,
//               label: role.role_name,
//             }))
//           );
//         } else {
//           toast.error("Unexpected response structure for roles.");
//           console.error("Unexpected response structure:", data);
//         }
//       } catch (error) {
//         toast.error("Error fetching roles.");
//         console.error("Error fetching roles:", error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (show) {
//       fetchDepartments();
//       fetchRoles();
//     }
//   }, [show]);

//   const handleSubmit = async () => {
//     if (!name || !email || !selectedDepartments.length || !selectedRole) {
//       toast.error("All fields are required.");
//       return;
//     }

//     const agent = {
//       agent_name: name,
//       agent_email: email,
//       selected_department_ids: selectedDepartments.map((d) => d.value),
//       role_id: selectedRole.value, // Store single role value
//     };

//     // Call the function to register the agent
//     await registerAgent(agent);
//   };

//   const registerAgent = async (agent) => {
//     const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
//     setLoading(true);

//     try {
//       const response = await fetch(
//         "https://ticketsystem.techfluxsolutions.com/agents/register",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//           body: JSON.stringify(agent),
//         }
//       );

//       if (!response.ok) {
//         const errorText = await response.text();
//         console.error("Registration error:", response.status, errorText);
//         throw new Error(`Error registering agent: ${response.status} - ${errorText}`);
//       }

//       const result = await response.json();
//       toast.success(`Agent ${result.agent_name} registered successfully.`);
//       fetchAgents();
//       console.log("Registered agent:", result);
      
//       // Optionally, you can invoke onCreate if necessary
//       // onCreate(result);

//       resetForm();
//       onHide();
//     } catch (error) {
//       toast.error("Error registering agent.");
//       console.error("Error registering agent:", error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <>
//       <Modal show={show} onHide={onHide} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Create New Agent</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {loading ? (
//             <Loader /> // Display loader if data is loading
//           ) : (
//             <Form>
//               <Form.Group controlId="name">
//                 <Form.Label>Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Enter agent's name"
//                   required
//                   value={name}
//                   onChange={(e) => setName(e.target.value)}
//                 />
//               </Form.Group>

//               <Form.Group controlId="email">
//                 <Form.Label>Email</Form.Label>
//                 <Form.Control
//                   type="email"
//                   placeholder="Enter email"
//                   required
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                 />
//               </Form.Group>

//               <Form.Group controlId="departments">
//                 <Form.Label>Departments</Form.Label>
//                 <Select
//                   isMulti
//                   options={departmentsData || []} // Ensure departmentsData is an array
//                   value={selectedDepartments} // selectedDepartments should be an array
//                   onChange={setSelectedDepartments} // directly set selected options
//                   placeholder="Select departments"
//                   required
//                   isLoading={loading} // Show loading state if data is still being fetched
//                 />
//               </Form.Group>

//               <Form.Group controlId="role">
//                 <Form.Label>Role</Form.Label>
//                 <Select
//                   options={
//                     rolesData.length > 0
//                       ? rolesData
//                       : roles.map((r) => ({ value: r, label: r }))
//                   }
//                   value={selectedRole}
//                   onChange={setSelectedRole}
//                   required
//                   placeholder="Select role"
//                   isLoading={loading} // Show loading state if data is still being fetched
//                 />
//               </Form.Group>
//             </Form>
//           )}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={onHide}>
//             Cancel
//           </Button>
//           <Button className="btn-create-dept" onClick={handleSubmit}>
//             Create Agent
//           </Button>
//         </Modal.Footer>
//       </Modal>
//       <ToastContainer />
//     </>
//   );
// };

// export default AgentCreateModal;

import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Loader/Loader";

const AgentCreateModal = ({
  show,
  onHide,
  onCreate,
  fetchAgents,
  roles = ["Admin", "Support", "Manager"],
  departments = ["Sales", "Tech", "HR", "Customer Service"],
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [departmentsData, setDepartmentsData] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setName("");
    setEmail("");
    setSelectedDepartments([]);
    setSelectedRole(null);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

    if (!token) {
      toast.error("Authorization token is missing.");
      console.error("Token not found in session storage.");
      return;
    }

    const fetchDepartments = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://ticketsystem.techfluxsolutions.com/get_departments_new",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text();
          console.error("Fetch error:", response.status, errorText);
          throw new Error(`Error fetching departments: ${response.status}`);
        }

        const data = await response.json();

        if (Array.isArray(data.data)) {
          setDepartmentsData(
            data.data.map((dept) => ({
              value: dept.department_id,
              label: dept.department_name,
            }))
          );
        } else {
          toast.error("Unexpected response structure for departments.");
          console.error("Unexpected response structure:", data);
        }
      } catch (error) {
        toast.error("Error fetching departments.");
        console.error("Error fetching departments:", error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchRoles = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://ticketsystem.techfluxsolutions.com/get_roles",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text();
          console.error("Fetch error:", response.status, errorText);
          throw new Error(`Error fetching roles: ${response.status} - ${errorText}`);
        }

        const data = await response.json();
        console.log("Fetched roles:", data.roles);

        if (Array.isArray(data.roles)) {
          setRolesData(
            data.roles.map((role) => ({
              value: role.role_id,
              label: role.role_name,
            }))
          );
        } else if (Array.isArray(data.data)) {
          setRolesData(
            data.data.map((role) => ({
              value: role.role_id,
              label: role.role_name,
            }))
          );
        } else {
          toast.error("Unexpected response structure for roles.");
          console.error("Unexpected response structure:", data);
        }
      } catch (error) {
        toast.error("Error fetching roles.");
        console.error("Error fetching roles:", error.message);
      } finally {
        setLoading(false);
      }
    };

    if (show) {
      fetchDepartments();
      fetchRoles();
    }

    if (!show) {
      resetForm();
    }
  }, [show]);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (!name || !email || !selectedDepartments.length || !selectedRole) {
      toast.error("All fields are required.");
      return;
    }

    if (!isValidEmail(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    const agent = {
      agent_name: name,
      agent_email: email,
      selected_department_ids: selectedDepartments.map((d) => d.value),
      role_id: selectedRole.value,
    };

    await registerAgent(agent);
  };

  const registerAgent = async (agent) => {
    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
    setLoading(true);

    try {
      const response = await fetch(
        "https://ticketsystem.techfluxsolutions.com/agents/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(agent),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Registration error:", response.status, errorText);
        throw new Error(`Error registering agent: ${response.status} - ${errorText}`);
      }

      const result = await response.json();
      toast.success(`Agent ${result.agent_name} registered successfully.`);
      fetchAgents();
      console.log("Registered agent:", result);

      resetForm();
      onHide();
    } catch (error) {
      toast.error("Error registering agent.");
      console.error("Error registering agent:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create New Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Loader />
          ) : (
            <Form>
              <Form.Group controlId="name" className="mb-3">
                <Form.Label>Name<span className="text-danger">*</span></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter agent's name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="email" className="mb-3">
                <Form.Label>Email<span className="text-danger">*</span></Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="departments" className="mb-3">
                <Form.Label>Departments<span className="text-danger">*</span></Form.Label>
                <Select
                  isMulti
                  options={departmentsData || []}
                  value={selectedDepartments}
                  onChange={setSelectedDepartments}
                  placeholder="Select departments"
                  required
                  isLoading={loading}
                />
              </Form.Group>

              <Form.Group controlId="role" className="mb-3">
                <Form.Label>Role<span className="text-danger">*</span></Form.Label>
                <Select
                  options={
                    rolesData.length > 0
                      ? rolesData
                      : roles.map((r) => ({ value: r, label: r }))
                  }
                  value={selectedRole}
                  onChange={setSelectedRole}
                  required
                  placeholder="Select role"
                  isLoading={loading}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button className="btn-create-dept" onClick={handleSubmit}>
            Create Agent
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default AgentCreateModal;
