// import React, { useState } from 'react';
// import { Modal, Button, Form } from 'react-bootstrap';
// import Select from 'react-select';  // Import react-select for multi-select dropdown
// import './CreateDepartmentModal.css'

// const CreateDepartmentModal = ({ show, onHide, onCreate }) => {
//     const [name, setName] = useState('');
//     const [selectedAgents, setSelectedAgents] = useState([]);
//     const [businessHours, setBusinessHours] = useState('General Working Hours');

//     // const agentOptions = [
//     //     { value: 1, label: 'Agent 1' },
//     //     { value: 2, label: 'Agent 2' },
//     //     { value: 3, label: 'Agent 3' },
//     //     { value: 4, label: 'Agent 4' },
//     //     // Add more agents as needed
//     // ];

//     // Options for business hours
//     // const businessHoursOptions = [
//     //     { value: 'General Working Hours', label: 'General Working Hours' }
//     // ];

//     const handleSubmit = () => {
//         // Extract agent values (numbers) from selected options
//         const agents = selectedAgents.map(agent => agent.value);

//         onCreate({ id: Date.now(), name, agents, businessHours });
//         setName('');
//         setSelectedAgents([]);
//         setBusinessHours('General Working Hours'); // Reset to default value
//     };

//     return (
//         <Modal show={show} onHide={onHide} centered>
//             <Modal.Header closeButton>
//                 <Modal.Title>Create Department</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//                 <Form>
//                     <Form.Group>
//                         <Form.Label>Department Name</Form.Label>
//                         <Form.Control
//                             type="text"
//                             className='form-control-department'
//                             value={name}
//                             onChange={(e) => setName(e.target.value)}
//                         />
//                     </Form.Group>
//                     {/* <Form.Group>
//                         <Form.Label>Number of Agents</Form.Label>
//                         <Select
//                             isMulti
//                             value={selectedAgents}  // Display selected agents
//                             onChange={setSelectedAgents}  // Handle select and deselect
//                             options={agentOptions}  // Options to display
//                             className='form-control-department'
//                             placeholder="Select Agents"
//                         />
//                     </Form.Group> */}
//                     {/* <Form.Group>
//                         <Form.Label>Business Hours</Form.Label>
//                         <Select
//                             value={businessHoursOptions.find(option => option.value === businessHours)} // Display current business hours
//                             onChange={(selectedOption) => setBusinessHours(selectedOption.value)} // Handle selection
//                             options={businessHoursOptions} // Options to display
//                             className='form-control-department'
//                             placeholder="Select Business Hours"
//                         />
//                     </Form.Group> */}
//                 </Form>
//             </Modal.Body>
//             <Modal.Footer>
//                 <Button variant="secondary" onClick={onHide}>
//                     Cancel
//                 </Button>
//                 <Button variant="primary" onClick={handleSubmit}>
//                     Create
//                 </Button>
//             </Modal.Footer>
//         </Modal>
//     );
// };

// export default CreateDepartmentModal;

import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import Loader from "./../../../Loader/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CreateDepartmentModal.css";

const CreateDepartmentModal = ({ show, onHide, onCreate ,fetchDepartments}) => {
  const [name, setName] = useState("");
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [businessHours, setBusinessHours] = useState("General Working Hours");
  const [loading, setLoading] = useState(false);

  //// Anurag's Code
  useEffect(() => {
    // Clear state when the modal opens or closes
    if (!show) {
      setName("");
      setSelectedAgents([]);
      setBusinessHours("General Working Hours");
    }
  }, [show]);



  // const handleSubmit = async () => {
  //   const agents = selectedAgents.map((agent) => agent.value);
  //   setLoading(true);

  const handleSubmit = async () => {
    if (!name.trim()) {
      // Show an error toast if the name field is empty
      toast.error("Department name is required.");
      return;
    }

    const agents = selectedAgents.map((agent) => agent.value);
    setLoading(true);


    try {
      // Retrieve TicketSystemSuperAdminToken from localStorage
      const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

      const response = await axios.post(
        "https://ticketsystem.techfluxsolutions.com/add_department_new",
        {
          department_name: name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      onCreate({ id: Date.now(), name, agents, businessHours });
      setName("");
      setSelectedAgents([]);
      setBusinessHours("General Working Hours");
      onHide();

      toast.success("Department created successfully!");
      fetchDepartments();
    } catch (error) {
      toast.error("Failed to create department. Please try again.");
      console.error("Error creating department:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create Department</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Department Name<span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="text"
                className="form-control-department"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Department Name"
                required
              />
            </Form.Group>
            {/* Uncomment and populate agentOptions to use */}
            {/* <Form.Group>
                            <Form.Label>Number of Agents</Form.Label>
                            <Select
                                isMulti
                                value={selectedAgents}
                                onChange={setSelectedAgents}
                                options={agentOptions}
                                className='form-control-department'
                                placeholder="Select Agents"
                            />
                        </Form.Group> */}
            {/* Uncomment and populate businessHoursOptions to use */}
            {/* <Form.Group>
                            <Form.Label>Business Hours</Form.Label>
                            <Select
                                value={businessHoursOptions.find(option => option.value === businessHours)}
                                onChange={(selectedOption) => setBusinessHours(selectedOption.value)}
                                options={businessHoursOptions}
                                className='form-control-department'
                                placeholder="Select Business Hours"
                            />
                        </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button className="btn-create-dept" onClick={handleSubmit} disabled={loading}>
            {loading ? "Creating..." : "Create"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateDepartmentModal;
