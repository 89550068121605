// import React from "react";
// import { Line } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   LineElement,
//   PointElement,
//   CategoryScale,
//   LinearScale,
//   Tooltip,
//   Legend,
// } from "chart.js";

// // Register chart components
// ChartJS.register(
//   LineElement,
//   PointElement,
//   CategoryScale,
//   LinearScale,
//   Tooltip,
//   Legend
// );

// const GraphSection = () => {
//   const data = {
//     labels: Array.from({ length: 24 }, (_, i) => i.toString()), // Hours of the day (0-23)
//     datasets: [
//       {
//         label: "Today's trends",
//         data: [0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 2.5, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0],
//         borderColor: "#4285F4",
//         backgroundColor: "rgba(66, 133, 244, 0.2)",
//         pointBackgroundColor: "#4285F4",
//         pointBorderColor: "#fff",
//         pointRadius: 5,
//         tension: 0, // Smooth curve
//       },
//     ],
//   };

//   const options = {
//     responsive: true,
//     maintainAspectRatio: true, // Adjust for better layout on large screens
//     plugins: {
//       legend: {
//         display: true, // Show legend for clarity
//       },
//       tooltip: {
//         enabled: true,
//       },
//       beforeDraw: (chart) => {
//         const { ctx, width, height } = chart.canvas;
//         ctx.save();
//         ctx.fillStyle = "#ffffff"; // White canvas background
//         ctx.fillRect(0, 0, width, height);
//         ctx.restore();
//       },
//     },
//     scales: {
//       x: {
//         title: {
//           display: true,
//           text: "Created date - Hour of the Day",
//         },
//         ticks: {
//           stepSize: 1,
//         },
//       },
//       y: {
//         title: {
//           display: true,
//           text: "Count",
//         },
//         beginAtZero: true,
//         max: 3,
//       },
//     },
//   };

//   return (
//     <div className="graph-wrapper bg-white">
//       <Line data={data} options={options} />
//     </div>
//   );
// };

// export default GraphSection;


// import React from 'react';
// import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

// const GraphSection = () => {
//   // Transform the data into the format Recharts expects
//   const data = Array.from({ length: 24 }, (_, i) => ({
//     hour: i.toString(),
//     value: [0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 2.5, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0][i]
//   }));

//   return (
//     <div className="container bg-white">
//       <div className="row">
//         <div className="col-12">
//           <div className="graph-wrapper">
//             <div className="graph-container">
//               <div style={{ width: '100%', height: '400px' }}>
//                 <ResponsiveContainer width="100%" height="100%">
//                   <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 40 }}>
//                     <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
//                     <XAxis 
//                       dataKey="hour"
//                       label={{ 
//                         value: 'Created date - Hour of the Day', 
//                         position: 'bottom',
//                         offset: 20
//                       }}
//                     />
//                     <YAxis
//                       label={{ 
//                         value: 'Count', 
//                         angle: -90, 
//                         position: 'insideLeft',
//                         offset: 10
//                       }}
//                       domain={[0, 3]}
//                     />
//                     <Tooltip 
//                       contentStyle={{ 
//                         backgroundColor: 'white', 
//                         border: '1px solid #dee2e6',
//                         borderRadius: '0.25rem',
//                         padding: '0.5rem'
//                       }} 
//                     />
//                     <Legend wrapperStyle={{ paddingTop: '20px' }}/>
//                     <Line
//                       type="linear"
//                       dataKey="value"
//                       name="Today's trends"
//                       stroke="#4285F4"
//                       strokeWidth={2}
//                       dot={{
//                         stroke: '#4285F4',
//                         strokeWidth: 2,
//                         r: 4,
//                         fill: 'white'
//                       }}
//                       activeDot={{
//                         r: 6,
//                         stroke: '#4285F4',
//                         strokeWidth: 2,
//                         fill: 'white'
//                       }}
//                     />
//                   </LineChart>
//                 </ResponsiveContainer>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default GraphSection;





// import React from 'react';
// import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
// import './GraphSection.css'

// const GraphSection = () => {
//   // Transform the data into the format Recharts expects
//   const data = Array.from({ length: 24 }, (_, i) => ({
//     hour: i.toString(),
//     value: [0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0][i]
    
//   }));

//   const CustomTooltip = ({ active, payload, label }) => {
//     if (active && payload && payload.length) {
//       return (
//         <div className="data-graph-tooltip">
//           <p className="mb-1">{`Hour: ${label}`}</p>
//           <p className="mb-0">{`Value: ${payload[0].value}`}</p>
//         </div>
//       );
//     }
//     return null;
//   };

//   return (
//     <div className=" col-12 container-fluid">
//       <div className="row">
//         <div className="col-12">
//           <div className="data-graph-wrapper">
//             <div className="data-graph-container">
//               <div className="data-graph-responsive">
//                 <ResponsiveContainer width="100%" height="100%">
//                   <LineChart 
//                     data={data} 
//                     margin={{ 
//                       top: 20, 
//                       right: 20, 
//                       left: 20, 
//                       bottom: 40 
//                     }}
//                   >
//                     <CartesianGrid 
//                       strokeDasharray="3 3" 
//                       stroke="#eee"
//                       opacity={0.5}
//                     />
//                     <XAxis 
//                       dataKey="hour"
//                       tick={{ fontSize: window.innerWidth < 576 ? 10 : 12 }}
//                       label={{ 
//                         value: 'Hour of Day', 
//                         position: 'bottom',
//                         offset: 20,
//                         fontSize: window.innerWidth < 576 ? 12 : 14
//                       }}
//                       padding={{ left: 10, right: 10 }}
//                     />
//                     <YAxis
//                       tick={{ fontSize: window.innerWidth < 576 ? 10 : 12 }}
//                       label={{ 
//                         value: 'Count', 
//                         angle: -90, 
//                         position: 'insideLeft',
//                         offset: 10,
//                         fontSize: window.innerWidth < 576 ? 12 : 14
//                       }}
//                       domain={[0, 3]}
//                       padding={{ top: 20, bottom: 20 }}
//                     />
//                     <Tooltip content={<CustomTooltip />} />
//                     <Legend 
//                       wrapperClassName="data-graph-legend"
//                       iconSize={window.innerWidth < 576 ? 8 : 10}
//                     />
//                     <Line
//                       type="lenear"
//                       dataKey="value"
//                     //   name="Today's trends"
//                       className='todayTrends'
//                       stroke="#4285F4"
//                       strokeWidth={window.innerWidth < 576 ? 1.5 : 2}
//                       dot={{
//                         stroke: '#4285F4',
//                         strokeWidth: window.innerWidth < 576 ? 1.5 : 2,
//                         r: window.innerWidth < 576 ? 3 : 4,
//                         fill: 'white'
//                       }}
//                       activeDot={{
//                         r: window.innerWidth < 576 ? 4 : 6,
//                         stroke: '#4285F4',
//                         strokeWidth: 0,
//                         fill: 'white'
//                       }}
//                     />
//                   </LineChart>
//                 </ResponsiveContainer>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default GraphSection;


import React, { useState, useEffect } from 'react';
import './GraphSection.css';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const GraphSection = () => {
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [currentMonthName, setCurrentMonthName] = useState('');

  useEffect(() => {
    const getDaysInMonth = (month, year) => {
      return new Date(year, month, 0).getDate(); // Get total days in the month
    };

    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June', 
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // getMonth() is 0-indexed, so add 1
    const currentYear = currentDate.getFullYear();
    const currentMonthName = monthNames[currentDate.getMonth()]; // Get current month name

    const days = Array.from({ length: getDaysInMonth(currentMonth, currentYear) }, (_, i) => (i + 1).toString());
    setDaysInMonth(days);
    setCurrentMonthName(currentMonthName);
  }, []);

  // Data for the bar chart
  const data = {
    labels: daysInMonth,
    datasets: [
      {
        label: 'Total Tickets',
        data: Array(daysInMonth.length).fill().map(() => Math.floor(Math.random() * 10) + 1), // Random data for each day
        backgroundColor: '#12344D',
        borderColor: 'black',
        borderWidth: 1,
      },
      {
        label: 'Tickets Resolved',
        data: Array(daysInMonth.length).fill().map(() => Math.floor(Math.random() * 10) + 1), // Random data for each day
        backgroundColor: '#2c5f86',
        borderColor: 'black',
        borderWidth: 1,
      },
    ],
  };

  // Options for the bar chart
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  return (
    <div className="container-graph container-recent-activity" style={{ overflowX: 'auto' }}>
      {/* Display current month name above the graph */}
      <h3 style={{ textAlign: 'center', marginBottom: '20px', color: '#12344D' }}>
        {currentMonthName}
      </h3>

      <div style={{ width: '2000px', height: '400px' }}> {/* Set large width to allow horizontal scrolling */}
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default GraphSection;

