// import React from 'react';
// import { Navbar, Container, Nav } from 'react-bootstrap';
// import { FaBars, FaBell } from 'react-icons/fa';
// import { HiOutlineTicket } from "react-icons/hi2";
// import "./NavbarComponent.css";

// const NavbarComponent = ({ toggleSidebar, pageTitle, isOpen }) => {
//     return (
//         <Navbar className='Navbar-TicketSysteam' expand="lg">
//             <Container fluid>
//                 <div className='navbar-spacing'>
//                     {/* Move the logo icon to the left of the hamburger icon */}
//                     <Navbar.Brand className='Navbar-components' href="#">
//                         <HiOutlineTicket className="icon" />
//                         {isOpen && <span className="logo-text">Ticket Systeam</span>} {/* Show text only if isOpen is true */}
//                     </Navbar.Brand>
//                     <FaBars className='hamburger-navbar' onClick={toggleSidebar} />
//                     <Navbar.Brand className='Navbar-components' href="#">{pageTitle}</Navbar.Brand>
//                 </div>
//                 <Navbar.Toggle aria-controls="navbar-nav" />
//                 <Navbar.Collapse className="navbar-nav-subcomponents">
//                     <Nav className="navbar-subcomponents ml-auto">
//                         {/* Replaced "Home" text with a bell icon */}
//                         <Nav.Link className='Navbar-childrens' href="#notifications">
//                             <FaBell className="bell-icon" />
//                             <span className="notification-dot"></span>
//                         </Nav.Link>
//                         <Nav.Link className='Navbar-childrens' href="#profile">Profile</Nav.Link>
//                     </Nav>
//                 </Navbar.Collapse>
//             </Container>
//         </Navbar>
//     );
// };

// export default NavbarComponent;





import React, { useState } from 'react';
import { Navbar, Container, Nav, Dropdown } from 'react-bootstrap';
import { FaBars, FaBell, FaUser } from 'react-icons/fa';
import { HiOutlineTicket } from "react-icons/hi2";
import LogoutModal from '../../Pages/LogoutModal/LogoutModal'; // Import the LogoutModal component
import "./NavbarComponent.css";

const NavbarComponent = ({ toggleSidebar, pageTitle, isOpen }) => {
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    const handleShowLogoutModal = () => setShowLogoutModal(true);
    const handleCloseLogoutModal = () => setShowLogoutModal(false);

    return (
        <Navbar className='Navbar-TicketSysteam' expand="lg">
            <Container fluid>
                <div className='navbar-spacing'>
                    <Navbar.Brand className='Navbar-components' href="#">
                        <HiOutlineTicket className="icon" />
                        {isOpen && <span className="logo-text">Ticket System</span>}
                    </Navbar.Brand>
                    <FaBars className='hamburger-navbar' onClick={toggleSidebar} />
                    <Navbar.Brand className='Navbar-components' href="#">{pageTitle}</Navbar.Brand>
                </div>
                <Navbar.Toggle aria-controls="navbar-nav" />
                <Navbar.Collapse className="navbar-nav-subcomponents">
                    <Nav className="navbar-subcomponents ml-auto">
                        <Nav.Link className='Navbar-childrens' href="#notifications">
                            {/* <FaBell className="bell-icon" />
                            <span className="notification-dot"></span> */}
                        </Nav.Link>
                        
                        {/* Profile dropdown */}
                        <Dropdown align="end" className='Navbar-childrens'>
                            <Dropdown.Toggle as={Nav.Link} className='Navbar-childrens'>
                                <FaUser className="user-icon" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='droupdown-menu-childrens'>
                                <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                                <Dropdown.Item onClick={handleShowLogoutModal}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>

            {/* Render LogoutModal and pass show and handleClose props */}
            <LogoutModal show={showLogoutModal} handleClose={handleCloseLogoutModal} />
        </Navbar>
    );
};

export default NavbarComponent;