import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Mail_fromListView.css";
import Loader from "../../../Loader/Loader";
import { toast } from "react-toastify";

const Mail_fromListViewReply = ({ticketId ,newReplies}) => {
  const [threadData, setThreadData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

        if (!token) {
          throw new Error("No authentication token found in session storage.");
        }
        setLoading(true);

        const response = await axios.get(
          `https://ticketsystem.techfluxsolutions.com/getRepliesByTicketId/${ticketId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLoading(false);

        console.log("API Response:", response.data); // Log the response data

        // Extract replies and transform into an array format
        const replies = response.data.data.replies;
        const formattedReplies = Object.values(replies).map((reply) => ({
          sender: reply.ticket_reply_cc.join(", "), // Join CC addresses to display
          email: reply.ticket_reply_bcc.join(", "), // Join BCC addresses to display
          message: reply.ticket_reply_message,
          // date: new Date().toLocaleString(), // Add a placeholder for date; you can replace this with actual dates if available
          replies: [], // Placeholder for nested replies if needed
        }));

        // Set the thread data
        setThreadData(formattedReplies);
      } catch (err) {
        // setError(err.message);
        toast.warning("There is no conversation..")
      } finally {
        setLoading(false);
      }
    };


    useEffect(() => {
    fetchData();
  }, [ticketId]);



  useEffect(() => {

    setThreadData(newReplies);
    
  }, [newReplies]);

  


  if (loading) {
    return <Loader />;
  }

  const renderThread = (thread) => {
    if (!Array.isArray(thread)) {
      return <div>Invalid thread data</div>;
    }

    return thread.map((email, index) => (
      <div key={index} className="mail-thread-item mb-5">
        <div className="mail-thread-header">
          <span className="mail-thread-sender">{email.sender}</span>
          <span className="mail-thread-address">&lt;{email.email}&gt;</span>
          <span className="mail-thread-date">{email.date}</span>
        </div>
        <div
          className="mail-thread-message"
          dangerouslySetInnerHTML={{ __html: email.message }}
        ></div>
        {email.replies.length > 0 && (
          <div className="mail-thread-replies">
            {renderThread(email.replies)}
          </div>
        )}
      </div>
    ));
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="mail-thread-container">{renderThread(threadData)}</div>
  );
};

export default Mail_fromListViewReply;
